<script setup>
import GeneralInfoForm from '@/views/components/GeneralInfoForm.vue';
import { useRouter } from 'vue-router';
import { executive as getExecutive, updateExecutive } from '@/network/apis/executives';
import { toast } from 'vue3-toastify';
import { inject, onMounted, ref } from 'vue';
import * as _ from 'lodash';
import { getCompanies } from '@/network/apis/companies';
import Loading from '@/components/Loading.vue';

const router = useRouter();
const userId = router.currentRoute.value.params.id;

const l = inject('L');

const language = (text) => {
  return _.get(l, text, text);
};

const onSubmit = async (values) => {
  const comNm = companyOptions.value.find(item => item.value === values.comId).text;
  const marriageDt = values.marriageYn === 'Y' ? values.marriageDt : null;

  const rest = await updateExecutive(userId, { ...values, comNm, marriageDt });

  if (!rest) {
    return;
  }

  router.push(`/executive/${userId}`).then(() => {
    toast.success(language('executiveUpdated'));
  });
};

const goBack = () => {
  router.push(`/executive/${userId}`)
};

const companyOptions = ref([]);
const formValues = ref(null);

onMounted(async () => {
  const response  = await getCompanies({ limit: 100 });

  companyOptions.value = response.companies.map(item => {
    return {
      value: item.id,
      text: item.companyName
    }
  });

  const executive = await getExecutive(userId);

  if (typeof executive === 'string') {
    router.push('/executives').then(() => {
      toast.error(language('executiveNotFound'));
    });
    return;
  }

  formValues.value = {
    comId: executive.employee.companyId,
    empNm: executive.employee.name,
    empNum: executive.employee.empNumber,
    birthDt: executive.employee.birthday,
    phoneNum: executive.employee.phone,
    email: executive.employee.email,
    addr: executive.employee.address,
    addrDetail: executive.employee.addressDetail,
    postNum: executive.employee.postNum,
    marriageYn: executive.employee.weddingYear,
    empId: executive.employee.empId,
    designation: executive.employee.designation,
    position: executive.employee.position,
    rank: executive.employee.rank,
    marriageDt: executive.employee.marriageDate,
    media: executive.employee.media,
    empDept: executive.employee.department,
  }
})

</script>

<template>
  <div v-if="!formValues" class="card min-vh-100 pb-4">
    <div class="card-body">
      <Loading />
    </div>
  </div>
  <GeneralInfoForm
      v-if="!!formValues"
      :company-options="companyOptions"
      :form-values="formValues"
      @on-submit="onSubmit"
      @on-back="goBack"
  />
</template>