<script setup>
import { ref, onMounted, computed, inject } from 'vue';
import * as _ from 'lodash';
import { useRoute } from 'vue-router';
import { useMainStore } from '@/store/pinia';

import CompensationChart from '@/components/Charts/Compensation.vue';
import { listSalary, editSalary, createSalary, deleteSalary } from '@/network/apis/salary';
import Loading from '@/components/Loading';
import NoResult from '@/components/NoResult';
import PopupAddEdit from '@/components/Actions/PopupAddEdit';
import { access } from '@/ultis';
import InfoItem from '../InfoItem';

const size  = computed(() => window.innerWidth);
const store = useMainStore();
const accessCode  = computed(() => parseInt(store.auth?.user?.sub_code.value));

const loading = ref(true);
const l = inject('L');
const salaries = ref([]);
const language = (text) => {
  return l.executiveInfo.compensation[_.camelCase(text)];
};
const route = useRoute();
const excId = route.params.id;
const params = ref({
  limit: 3,
  page: 1,
});

const labels = ref([]);
const datasets = ref([]);

const fetchData = async () => {
  loading.value = true;

  const res = await listSalary({ ...params.value, empId: excId });
  if (!res) return;

  salaries.value = _.reverse(convertData(res.salaries));
  dataChart(res.salaries)

  setTimeout(() => {
    loading.value = false;
  }, 500);
};

const dataChart = (arr) => {
  const annuals = [];
  const incentives = [];
  const labs = [];

  if (_.size(arr) === 0) return;

  for (const item of arr) {
    annuals.push(item.salAnnual);
    incentives.push(item.salIncentive);
    labs.push(item.salYear.toString());
  }

  labels.value = labs;
  datasets.value = [{
    label: language('Annual salary'),
    backgroundColor: '#f87979',
    data: annuals
  },
  {
    label: language('Incentive'),
    backgroundColor: '#7acbf9',
    data: incentives
  }];
}

const convertData = (arr) => {
  return _.reduce(
    arr,
    (list, item) => {
      const original = {
        salYear: item.salYear,
        salAnnual: item.salAnnual,
        salIncentive: item.salIncentive,
        salNote: item.salNote
      };

      list.push([
        {
          label: language('Year'),
          value: item.salYear,
          refId: item.salId,
          original,
        },
        {
          label: language('Annual Salary'),
          value: item.salAnnual,
          refId: item.salId,
          original
        },
        {
          label: language('Incentive'),
          value: item.salIncentive,
          refId: item.salId,
          original
        },
        {
          label: language('Note'),
          value: item.salNote,
          refId: item.salId,
          original
        },
      ]);

      return list;
    },
    []
  );
};

onMounted(() => fetchData());

const delFn = async (id) => {
  await deleteSalary(id);
  fetchData();

  return true;
};

const addEditFn = async (params, id) => {
  let res = null;

  if (id) {
    res = await editSalary(id, params)
  } else {
    res = await createSalary(params)
  }

  if (!res) return false;

  return true;
};

const isNoResult = computed(() => {
  return _.size(salaries.value) === 0 && !loading.value;
});
</script>
<template>
  <div v-if="!loading" :class="size > 767 ? 'p-4' : 'pt-4'">
    <div class="card-header-custom pb-4 d-flex" :class="size > 767 ? 'justify-content-end' : 'justify-content-center'">
      <PopupAddEdit v-if="access(accessCode, ['MANAGER', 'ADMIN'])" :parent-id="excId" :add-edit-fn="addEditFn" title="Add" size="md" type="SALARY" :is-add="true" :options="{ refreshData: fetchData, showMobile: true }" />
    </div>
    <CompensationChart v-if="!isNoResult" :data="{ labels, datasets }" height="400" width="100%" />
  </div>
  <div>
    <Loading v-if="loading" />
    <NoResult v-if="isNoResult" />
    <div v-if="!isNoResult">
      <InfoItem
        v-for="(item, key) in salaries"
        :key="key"
        :data="item"
        :add-edit-fn="addEditFn"
        :del-fn="delFn"
        type="SALARY"
        :emp-id="excId"
        :options="{ refreshData: fetchData }"
      />
    </div>
  </div>
</template>
