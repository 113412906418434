<script setup>
import { defineProps, computed } from "vue";
import { useMainStore } from '@/store/pinia';

import PopupDelete from '@/components/Actions/PopupDelete';
import PopupAddEdit from '@/components/Actions/PopupAddEdit';
import { access } from '@/ultis';

defineProps({
  data: Array,
  addEditFn: Function,
  delFn: Function,
  type: String,
  empId: String,
  options: Object,
});

const size  = computed(() => window.innerWidth);
const store = useMainStore();
const accessCode  = computed(() => parseInt(store.auth?.user?.sub_code.value));
</script>
<template>
  <div class="section-box-item p-3 mb-3">
    <div class="row mb-1" v-for="(item, key) in data" :key="key">
      <div class="col-12 d-flex justify-content-center box-header-item" v-if="key === 0 && access(accessCode, ['MANAGER', 'ADMIN']) && size < 768">
        <span>
          <PopupAddEdit :ref-id="item.refId" :parent-id="empId" :add-edit-fn="addEditFn" title="Edit" size="md" :type="type" :data="item.original" :options="options" />
          <PopupDelete :ref-id="item.refId" :call-api="delFn" />
        </span>
      </div>
      <div class="col-5 col-md-4 col-lg-2 col-xl-2 col-xxl-1 text-bold d-flex justify-content-between">
        {{ item.label }}
      </div>
      <div class="col-7 col-md-6 col-lg-8 col-xl-8 col-xxl-9 pre-text">{{ item.value }}</div>
      <div class="col-md-2 col-lg-2 d-flex justify-content-end" v-if="key === 0 && access(accessCode, ['MANAGER', 'ADMIN']) && size >= 768">
        <PopupAddEdit :ref-id="item.refId" :parent-id="empId" :add-edit-fn="addEditFn" title="Edit" size="md" :type="type" :data="item.original" :options="options" />
        <PopupDelete :ref-id="item.refId" :call-api="delFn" />
      </div>
    </div>
  </div>
</template>
 