<script setup>
import { computed, ref, inject } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useMainStore } from '@/store/pinia';
import * as _ from "lodash";

import Breadcrumbs from "./Breadcrumbs.vue";

const showMenu = ref(false);
const route = useRoute();
const router = useRouter();
const store = useMainStore();
const $swal = inject('$swal');
const l = inject('L');

const currentRouteName = computed(() => {
  return l.route[_.camelCase(route.name)];
});
const currentDirectory = computed(() => {
  let dir = route.path.split("/")[1];
  return l.route[_.camelCase(dir.charAt(0).toUpperCase() + dir.slice(1))];
});

const currentUser  = computed(() => store.auth?.user);

const closeMenu = () => {
  setTimeout(() => {
    showMenu.value = false;
  }, 1000);
};

const logout = () => {
  $swal.fire({
    title: language("Are you sure"),
    text: language(`You want to logout from the system`),
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#d33",
    cancelButtonColor: "#ccc",
    confirmButtonText: language("Yes"),
    cancelButtonText: language("Cancel"),
    reverseButtons: true
  }).then( async (result) => {
    if (result.isConfirmed) {
      await store.removeAuth();

      return router.push('/login');
    }
  });
};

const showNav = async () => {
  await store.setNav(true);
}

const language = (text) => {
  return l[_.camelCase(text)];
};

</script>
<template>
  <nav
    class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
  >
    <div class="px-0 py-1 container-fluid">
      <a
        href="javascript:void(0)"
        @click="showNav"
        class="p-0 nav-link text-white nav-icon"
        id="iconNavbarSidenav"
      >
        <div class="sidenav-toggler-inner">
          <i class="sidenav-toggler-line bg-white"></i>
          <i class="sidenav-toggler-line bg-white"></i>
          <i class="sidenav-toggler-line bg-white"></i>
        </div>
      </a>
      <breadcrumbs
        :current-page="currentRouteName"
        :current-directory="currentDirectory"
        class="page-title"
      />

      <div
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        id="navbar"
      >
        <div class="pe-md-3 d-flex align-items-center ms-md-auto"></div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item dropdown d-flex align-items-center pe-2">
            <a
              href="#"
              class="p-0 nav-link text-white"
              :class="[showMenu ? 'show' : '']"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="showMenu = !showMenu"
              @blur="closeMenu"
            >
              <img
                src="../assets/img/avatar.jpg"
                class="avatar avatar-sm me-3"
                alt="user image"
              />
              <strong>{{ currentUser?.user_nm }}</strong>
            </a>
            <ul
              class="px-2 py-3 dropdown-menu dropdown-menu me-sm-n4"
              :class="showMenu ? 'show' : ''"
              aria-labelledby="dropdownMenuButton"
            >
              <li @click="logout">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <i class="cursor-pointer fa fa-sign-out"></i> {{ language('Logout') }}
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
