<script setup>
import { inject } from "vue";
import * as _ from 'lodash';
import { toast } from 'vue3-toastify';

const l = inject('L');
const $swal = inject('$swal');
const language = (text) => {
  return l[_.camelCase(text)];
};

const props = defineProps({
  refId: Number,
  callApi: Function,
  content: String
});

const del = (id) => {
  $swal.fire({
    title: language('Delete Title'),
    text: props.content ? language(props.content) : language('Delete Cannot Revert'),
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#d33",
    cancelButtonColor: "#ccc",
    confirmButtonText: language("Delete Confirm"),
    cancelButtonText: language("Cancel"),
    reverseButtons: true
  }).then( async (result) => {
    if (result.isConfirmed) {
      const res = await props.callApi(id);

      if (!res) return;

      toast.success(language('Deleted successfully!'));
    }
  });
};
</script>
<template>
  <button class="btn btn-sm btn-icon-only mb-0 mx-1 btn-danger" @click="del(refId)">
    <i class="far fa-trash-alt"></i>
  </button>
</template>
