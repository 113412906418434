<script setup>
import { ref, onMounted, inject, computed } from 'vue';
import {
  updateCharacteristic,
  getCharacteristic,
} from '@/network/apis/characteristic';
import { useRouter } from 'vue-router';
import * as _ from 'lodash';
import { useMainStore } from '@/store/pinia';

import { access } from '@/ultis';

const store = useMainStore();
const showEdit = ref(0);
const accessCode  = computed(() => parseInt(store.auth?.user?.sub_code.value));
const l = inject('L');
const router = useRouter();
let characteristic = ref();
let inputKeyword = ref();
let inputKeywordSpecialty = ref();
let saved = ref(false);
const timer = ref(null);

const language = (text) => {
  return l[_.camelCase(text)];
};
const fetchCharacteristic = async () => {
  const res = await getCharacteristic(router.currentRoute._value.params.id);
  if (!res) return;
  if (res.hobby) {
    characteristic.value = res.hobby ? res.hobby : {};
    inputKeyword.value = res.hobby.hobby;
    inputKeywordSpecialty.value = res.hobby.specialty;
  }
};

const handEdit = (index) => {
  showEdit.value = index;
}

onMounted(() =>
  setTimeout(() => {
    fetchCharacteristic();
  }, 1700)
);

const handleInput = () => {
  if (timer.value) {
    clearTimeout(timer.value);
  }
}

const saveCharacteristicDebounced = _.debounce(async () => {
  saved.value = false;
  handleInput();
  await updateCharacteristic(router.currentRoute._value.params.id, {
    ...characteristic.value,
    empId: router.currentRoute._value.params.id,
    hobby: inputKeyword.value,
    specialty: inputKeywordSpecialty.value,
  });

  setTimeout(() => {
    saved.value = true;
  })
  setTimeout(() => {
    saved.value = false;
  }, 2000)

  timer.value = setTimeout(() => {
    showEdit.value = 0;
  }, 3000)
}, 1000);
</script>
<template>
  <div class="row pt-4">
    <div class="col-md-6">
      <div class="text-md fs-4 fw-bold">{{ language('Hobby') }}</div>
      <div class="textarea-box">
        <i v-if="saved" class="fas saved fa-check-circle"></i>
        <textarea
          v-if="showEdit === 1"
          :placeholder="language('Placeholder Input Hobby')"
          rows="10"
          class="form-control"
          v-model="inputKeyword"
          @input="saveCharacteristicDebounced"
          :disabled="!access(accessCode, ['MANAGER', 'ADMIN'])"
        />
        <div class="pre-text p-4" v-if="showEdit != 1 || !showEdit" :class="access(accessCode, ['MANAGER', 'ADMIN']) ? 'box-content-text-area' : ''">
          {{ inputKeyword }}
          <i class="far fa-edit" v-if="access(accessCode, ['MANAGER', 'ADMIN'])" @click="handEdit(1)"></i>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="text-md fs-4 fw-bold">
        {{ language('Special skills') }}
      </div>
      <div class="textarea-box">
        <i v-if="saved" class="fas saved fa-check-circle"></i>
        <textarea
          v-if="showEdit === 2"
          :placeholder="language('Placeholder Input Hobby')"
          rows="10"
          class="form-control"
          v-model="inputKeywordSpecialty"
          @input="saveCharacteristicDebounced"
          :disabled="!access(accessCode, ['MANAGER', 'ADMIN'])"
        />
        <div class="pre-text p-4" v-if="showEdit != 2 || !showEdit" :class="access(accessCode, ['MANAGER', 'ADMIN']) ? 'box-content-text-area' : ''">
          {{ inputKeywordSpecialty }}
          <i class="far fa-edit" v-if="access(accessCode, ['MANAGER', 'ADMIN'])" @click="handEdit(2)"></i>
        </div>
      </div>
    </div>
  </div>
</template>
