<script setup>
import { onBeforeUnmount, onBeforeMount, inject } from 'vue';
import { useMainStore } from '@/store/pinia';
import * as _ from 'lodash';
import { useRouter } from 'vue-router';

import AppFooter from '@/components/Footer.vue';

const store = useMainStore();
const router = useRouter();
const l = inject('L');

onBeforeMount(() => {
  store.setLayout('none');
});
onBeforeUnmount(async () => {
  await store.setLayout('default');
});

const goto = () => {
  return router.push('/');
}

const language = (text) => {
  return l[_.camelCase(text)];
};
</script>
<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar isBtn="bg-gradient-light" />
      </div>
    </div>
  </div>
  <main class="main-content mt-0 vh-100">
    <div class="box-403 h-75"></div>
    <div class="box-button h-25 d-flex align-items-center justify-content-center">
      <button @click="goto()" class="btn btn-lg mb-0 mx-1 p-2 pt-1 pb-1 btn-outline-secondary">{{ language('Go To Dashboard') }} <i class="fas fa-arrow-right"></i></button>
    </div>
  </main>
  <app-footer />
</template>
