<script setup>
import { inject, defineProps, computed, ref } from 'vue';
import * as _ from 'lodash';
import { useMainStore } from '@/store/pinia';

import { randomColor, access } from '@/ultis';
import PopupDelete from '@/components/Actions/PopupDelete';
import PopupAddEdit from '@/components/Actions/PopupAddEdit';
import { editKpiMid, createKpiMid, deleteKpiMid } from '@/network/apis/kpi';

const l = inject('L');
const store = useMainStore();
const accessCode  = computed(() => parseInt(store.auth?.user?.sub_code.value));

const props = defineProps({
  kpis: Array,
  options: Object,
})

const show = ref(props.options.collapseIdx);
const isShow = ref(props.options.isQuarterShow);

const collapse = (index) => {
  if (show.value === index && isShow.value) {
    show.value = 0;
  } else {
    show.value = index;
  }

  isShow.value = true;
};

const quaterExts = computed(() => _.map(props.kpis, (k) => parseInt(k.quater)));

const addEditFn = async (params, id) => {
  let res = null;

  if (id) {
    res = await editKpiMid(id, params)
  } else {
    res = await createKpiMid(params);
  }

  if (!res) return false;

  return true;
};

const delFn = async (id) => {
  await deleteKpiMid(id);
  props.options.refreshData(props.options.kpiId);

  return true;
};

const language = (text) => {
  return l.executiveInfo.forms.evaluation[_.camelCase(text)];
};
</script>
<template>
  <h5 class="mb-2 d-flex justify-content-between">
    <span class="pointer" @click="collapse(options.kpiId)">
      {{ language('Quarterly Kpi') }}
      <i class="fas fa-caret-up" v-if="show === options.kpiId && isShow"></i>
      <i class="fas fa-caret-down" v-if="show !== options.kpiId || (show === options.kpiId && !isShow)"></i>
    </span>
    <PopupAddEdit v-if="access(accessCode, ['MANAGER', 'ADMIN'])" :add-edit-fn="addEditFn" :parent-id="options.empId" title="Add" size="md" type="EVALUATION_QUARTER" :is-add="true" :options="{ isAdd: true, ...options, quaterExts }" />
  </h5>
  <div v-if="show === options.kpiId && isShow">
    <div class="box-kpi-item" v-for="(item, k) in kpis" :key="k">
      <div class="d-flex justify-content-between box-kpi-title p-3 pt-2 pb-2" :style="{backgroundColor: randomColor(1, 0.2)}">
        <h6 class="mb-0">{{ language('Quarter') }} {{ item.quater }}</h6>
        <div>
          <PopupAddEdit v-if="access(accessCode, ['MANAGER', 'ADMIN'])" :parent-id="options.empId" :ref-id="item.id" :add-edit-fn="addEditFn" title="Edit" size="md" type="EVALUATION_QUARTER" :data="{ kpiMidQuater: item.quater, kpiMidRate: item.achievementRate, kpiMidNote: item.detailProress }" :options="{ ...options, quaterExts }" />
          <PopupDelete :ref-id="item.id" :call-api="delFn" v-if="access(accessCode, ['MANAGER', 'ADMIN'])" />
        </div>
      </div>
      <div class="kpi-item-content mb-4 pt-3 pb-3">
        <div class="row">
          <div class="col-6 col-md-4 col-xl-3 col-xxl-2 p-4 pt-0 pb-0">{{ language('Achievement Rate') }}</div>
          <div class="col-6 col-md-8 col-xl-9 col-xxl-10">{{ item.achievementRate }}%</div>
        </div>
        <div class="row">
          <div class="col-6 col-md-4 col-xl-3 col-xxl-2 p-4 pt-0 pb-0">{{ language('Detail Progress') }}</div>
          <div class="col-6 col-md-8 col-xl-9 col-xxl-10">{{ item.detailProress }}</div>
        </div>
      </div>
    </div>
    <NoResult v-if="_.size(kpis) === 0" />
  </div>
</template>
