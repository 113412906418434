<script setup>
import { ref, watch, defineEmits, inject } from 'vue';
import * as _ from 'lodash';
import Datepicker from '@vuepic/vue-datepicker';

const l = inject('L');
const language = (text) => {
  return l[_.camelCase(text)];
};

const props = defineProps({
  modelValue: String,
  disabled: Boolean,
  isString: Boolean,
  maxCurrent: Boolean,
});

const emit = defineEmits(['update:modelValue', 'change']);

const year = ref(props.modelValue);
const show = ref(props.disabled);

watch(() => props.modelValue, (newVal) => {
  year.value = newVal;
});

watch(() => props.disabled, (newVal) => {
  show.value = newVal;
});

const emitDateChange = () => {
  emit('update:modelValue', props.isString ? year.value.toString() : year.value);
  emit('change');
};

</script>
<template>
  <Datepicker 
    v-model="year" 
    format="yyyy"
    year-picker
    :enable-time-picker="false"
    auto-apply
    @update:model-value="emitDateChange"
    :placeholder="language('Select Year')"
    :disabled="show"
    :max-date="maxCurrent ? new Date() : ''"
  />
</template>