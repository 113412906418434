<script setup>
import { inject, computed } from 'vue';
import { useRoute } from 'vue-router';
import * as _ from 'lodash';
import { useMainStore } from '@/store/pinia';

import Item from './Item.vue';

const l = inject('L');
const store = useMainStore();
const accessCode  = computed(() => parseInt(store.auth?.user?.sub_code.value));

const language = (text) => {
  return l.sideBar[_.camelCase(text)];
};

const getRoute = () => {
  const route = useRoute();
  const routeArr = route.path.split('/');
  return routeArr[1];
};
</script>
<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="mt-3 nav-item">
        <h6
          class="ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="'ms-2'"
        >
          {{ language('Company Overview') }}
        </h6>
      </li>
      <li class="nav-item">
        <Item
          to="/overview"
          :class="getRoute() === 'overview' ? 'active' : ''"
          :navText="language('Overview')"
        >
          <template v-slot:icon>
            <i class="ni ni-chart-bar-32 text-primary text-sm opacity-10"></i>
          </template>
        </Item>
      </li>

      <li class="nav-item">
        <Item
          to="/executives"
          :class="
            getRoute() === 'executives' || getRoute() === 'executive'
              ? 'active'
              : ''
          "
          :navText="language('Executive List')"
        >
          <template v-slot:icon>
            <i class="ni ni-bullet-list-67 text-warning text-sm opacity-10"></i>
          </template>
        </Item>
      </li>

      <li class="nav-item">
        <Item
          to="/favorite"
          :class="getRoute() === 'favorite' ? 'active' : ''"
          :navText="language('Favorites')"
        >
          <template v-slot:icon>
            <i
              class="fas fa-star pointer text-success text-sm opacity-10 mb-1"
              style="color: #04de66"
            ></i>
            <!-- <i class="ni ni-favourite-28 text-success text-sm opacity-10"></i> -->
          </template>
        </Item>
      </li>

      <li class="mt-3 nav-item" v-if="accessCode >= 20">
        <h6
          class="ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="'ms-2'"
        >
          {{ language('System') }}
        </h6>
      </li>

      <li class="nav-item" v-if="accessCode >= 20">
        <Item
          to="/masters"
          :class="getRoute() === 'masters' ? 'active' : ''"
          :navText="language('Master Data')"
        >
          <template v-slot:icon>
            <i class="ni ni-settings text-info text-sm opacity-10"></i>
          </template>
        </Item>
      </li>

      <li class="nav-item" v-if="accessCode >= 20">
        <Item
          to="/sub-masters"
          :class="getRoute() === 'sub-masters' ? 'active' : ''"
          :navText="language('Sub Master Data')"
        >
          <template v-slot:icon>
            <i class="fas fa-lock text-info text-sm opacity-10"></i>
          </template>
        </Item>
      </li>

      <li class="nav-item" v-if="accessCode >= 20">
        <Item
          to="/companies"
          :class="getRoute() === 'companies' ? 'active' : ''"
          :navText="language('Companies')"
        >
          <template v-slot:icon>
            <i class="ni ni-building text-info text-sm opacity-10"></i>
          </template>
        </Item>
      </li>

      <li class="nav-item" v-if="accessCode >= 20">
        <Item
          to="/log"
          :class="getRoute().indexOf('log') != -1 ? 'active' : ''"
          :navText="language('Access Log')"
        >
          <template v-slot:icon>
            <i class="ni ni-tv-2 text-primary text-sm opacity-10"></i>
          </template>
        </Item>
      </li>
    </ul>
  </div>
</template>
