<script setup>
import { watch, computed, inject } from 'vue';
import * as _ from 'lodash';
import { Bar } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';

const l = inject('L');

const language = (text) => {
  return l.chart[_.camelCase(text)];
};

const size = computed(() => window.innerWidth);

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const props = defineProps({
  data: Array,
  title: String,
  height: Number,
  addClass: String,
  labelKey: String,
  valueKey: String,
  total: Number,
});

const randomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';

  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  return color;
};

const generateColors = (num) => {
  const colors = [];

  for (let i = 0; i < num; i++) {
    colors.push(randomColor());
  }

  return colors;
};

const totalPlugin = {
  id: 'totalPlugin',
  afterDraw: (chart) => {
    const { ctx, chartArea: { top, right } } = chart;

    ctx.save();
    ctx.font = 'bold 14px Arial';
    ctx.fillStyle = 'black';
    ctx.textAlign = 'right';
    ctx.fillText(`${language('Total')}:`, size.value > 767 ? (right + 100) : right, top);
    ctx.fillText(`${props.total} ${language('members')}`, size.value > 767 ? right + 100 : right, top + 20);
    ctx.restore();
  },
};

if (size.value > 767) {
  ChartJS.register(totalPlugin);
}

const chartData = computed(() => ({
  labels: _.map(props.data, (item) => item[props.labelKey]),
  datasets: [{
    backgroundColor: generateColors(_.size(props.data)),
    data: _.map(props.data, (item) => item[props.valueKey]),
  }]
}));

const chartOptions = computed(() => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: language(props.title) + (size.value < 768 ? `(${props.total} ${language('members')})` : ''),
      font: {
        size: size.value > 767 ? 30 : 20,
      },
    },
    totalPlugin: {},
  },
  layout: {
    padding: {
      left: size.value > 767 ? 120 : 10,
      right: size.value > 767 ? 120 : 10,
      top: 0,
      bottom: 0
    }
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      display: false,
      grid: {
        display: false,
      },
    },
  },
}));

watch(
  () => props.data,
  () => {
  },
  { deep: true }
);

</script>

<template>
  <div class="chart text-center" :class="addClass" :style="{height: `${height}px`}">
    <Bar :data="chartData" :options="chartOptions" />
  </div>
</template>