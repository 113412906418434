<script setup>
import { inject, computed, defineEmits, ref } from 'vue';
import * as _ from 'lodash';
import DatePicker from '@/components/DatePicker';

const l = inject('L');
const emits = defineEmits(['submitForm']);

const props = defineProps({
  data: {
    crAppointDt: String,
    crItem: String,
    crType: String,
    crEnterYn: String,
    crSdt: String,
    crEdt: String,
    crPosition: String,
    crDesignation: String,
    crRank: String,
    crDept: String,
    crNm: String,
  },
})

const form = computed(() => ({ ...props.data, crEnterYn: props.data?.crEnterYn ? props.data.crEnterYn : 'N' }));

const isSubmit = ref(false);
const errors = ref({});

const disabled = ref(false);

const validateForm = () => {
  const obj = form.value;

  for (const key of Object.keys(obj)) {
    if (errors.value[key]) {
      errors.value[key] = !obj[key];
    }

    if (key === 'crEnterYn') {
      disabled.value = obj.crEnterYn === 'Y';
    }
  }
};

const handleSubmit = async () => {
  isSubmit.value = true;
  validateForm();
  
  const checkError = Object.values(errors.value).some(
    (value) => value === true
  );

  if (checkError) {
    return;
  }

  emits('submitForm', form.value);
};

const language = (text, isCommon) => {
  if (isCommon) return l[_.camelCase(text)];

  return l.executiveInfo.forms.experience[_.camelCase(text)];
};

defineExpose({
  handleSubmit
});
</script>
<template>
  <form @submit.prevent="handleSubmit">
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="StartWorkingDate" class="form-label">{{
          language('Start working date')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <DatePicker v-model="form.crSdt" @change="validateForm" />
        <em class="red text-xs" v-if="isSubmit && errors.crSdt">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            v-model="form.crEnterYn"
            true-value="Y"
            false-value="N"
            id="CurrentlyWorking"
            :placeholder="language('Enter your text', true)"
            @change="validateForm"
          />
          <label for="CurrentlyWorking" class="form-check-label">{{
            language('Currently working')
          }}</label>
        </div>
        <em class="red text-xs" v-if="isSubmit && errors.crEnterYn">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="EndWorkingDate" class="form-label">{{
          language('End working date')
        }}</label>
      </div>

      <div class="col-md-7 col-xl-7 col-xxl-8">
        <DatePicker v-model="form.crEdt" @change="endWorkingDate" :disabled="disabled" />
        <em class="red text-xs" v-if="isSubmit && errors.crEdt">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="EstablishedDate" class="form-label">{{
          language('Established Date')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <DatePicker v-model="form.crAppointDt" @change="validateForm" />
        <em class="red text-xs" v-if="isSubmit && errors.crAppointDt">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="Company" class="form-label">{{
          language('Company')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="form.crNm"
          id="Company"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="50"
        />
        <em class="red text-xs" v-if="isSubmit && errors.crNm">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="WorkingField" class="form-label">{{
          language('Working Field')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="form.crItem"
          id="WorkingField"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="20"
        />
        <em class="red text-xs" v-if="isSubmit && errors.crItem">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="EmploymentType" class="form-label">{{
          language('Employment Type')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="form.crType"
          id="EmploymentType"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="100"
        />
        <em class="red text-xs" v-if="isSubmit && errors.crType">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="JobTitle" class="form-label">{{
          language('Job Title')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="form.crPosition"
          id="JobTitle"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="50"
        />
        <em class="red text-xs" v-if="isSubmit && errors.crPosition">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="Responsibility" class="form-label">{{
          language('Responsibility')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="form.crDesignation"
          id="Responsibility"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="50"
        />
        <em class="red text-xs" v-if="isSubmit && errors.crDesignation">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="JobLevel" class="form-label">{{
          language('Job Level')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="form.crRank"
          id="JobLevel"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="50"
        />
        <em class="red text-xs" v-if="isSubmit && errors.crRank">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="Department" class="form-label">{{
          language('Department')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="form.crDept"
          id="Department"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="50"
        />
        <em class="red text-xs" v-if="isSubmit && errors.crDept">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
  </form>
</template>
