<script setup>
import { inject, onMounted, ref, watch } from 'vue';
import * as _ from 'lodash';
import { useRoute } from 'vue-router';

import { getNetworks, deleteNetwork, createNetwork, editNetwork } from '@/network/apis/executives';
import InfoItem from '../../InfoItem';
import Loading from '@/components/Loading';
import NoData from '@/components/NoData';
import { date } from '@/ultis';

const props = defineProps({
  refresh: Boolean,
});

const route = useRoute();
const excId = route.params.id;
const loading = ref(true);

const l = inject('L');
const language = (text) => {
  return l.executiveInfo.forms.network[_.camelCase(text)];
};

const networks = ref([]);

const fetchData = async () => {
  loading.value = true;

  const res = await getNetworks(excId, {
    empId: excId,
  });

  if (!res) return;

  networks.value = convertData(res.networks);

  setTimeout(() => {
    loading.value = false;
  }, 1000);
};

onMounted(() => fetchData());

const convertData = (arr) => {
  return _.reduce(
    arr,
    (list, item) => {
      const original = {
        netNm: item.name,
        netPosition: item.jobTitle,
        netRelation: item.relationship,
        netCompany: item.affiliation,
        netWorkEdt: item.netWorkEdt,
        netWorkSdt: item.netWorkSdt,
        netProfile: item.mainBiography,
      };

      list.push([
        {
          label: language('Name'),
          value: item.name,
          refId: item.netId,
          original,
        },
        {
          label: language('Job Title'),
          value: item.jobTitle,
          refId: item.netId,
          original,
        },
        {
          label: language('Relationship'),
          value: item.relationship,
          refId: item.netId,
          original,
        },
        {
          label: language('Affiliation'),
          value: item.affiliation,
          refId: item.netId,
          original,
        },
        {
          label: language('Employment Period'),
          value:
            !item.netWorkSdt && !item.netWorkEdt ? '' : date(item.netWorkSdt) + ' ~ ' + date(item.netWorkEdt),
          refId: item.netId,
          original,
        },
        {
          label: language('Main biography'),
          value: item.mainBiography,
          refId: item.netId,
          original,
        },
      ]);

      return list;
    },
    []
  );
};

const delFn = async (id) => {
  await deleteNetwork(id);
  fetchData();

  return true;
};

const addEditFn = async (params, id) => {
  let res = null;

  if (id) {
    res = await editNetwork(id, params)
  } else {
    res = await createNetwork(params)
  }

  if (!res) return false;

  return true;
};

watch(
  () => props.refresh,
  () => fetchData(),
  { deep: true }
);
</script>
<template>
  <NoData v-if="!loading && _.size(networks) === 0" />
  <Loading v-if="loading" />
  <div v-if="!loading">
    <InfoItem
      v-for="(item, key) in networks"
      :key="key"
      :data="item"
      :add-edit-fn="addEditFn"
      :del-fn="delFn"
      type="NETWORK"
      :emp-id="excId"
      :options="{ refreshData: fetchData }"
    />
  </div>
</template>
