import { toast } from 'vue3-toastify';
import axios from 'axios';
import * as _ from 'lodash';
import api from './api';
import apiAuth from './apiAuth';
import language from '../consts/language';
import errors from '../consts/errors';

export const noneAuth = [
	'/login',
];

export const isQuery = [
  'get',
  'delete'
];

export let cancelTokens = {};

export const getCancelToken = (path) => {
  if (cancelTokens[path]) {
    cancelTokens[path].cancel('Operation canceled');
  }
  cancelTokens[path] = axios.CancelToken.source();
  return cancelTokens[path].token;
};

export const callApi = async (method, url, params, options) => {
	const { shouldShowToast = true } = options || {};

	try {
    const cancelToken = getCancelToken(url);
  	const uri = _.indexOf(isQuery, method) != -1 ? buildQuery(url, params) : url;
  	const instance = _.indexOf(noneAuth, url) === -1 ? apiAuth : api;
    const options = method === 'get' ? { cancelToken: cancelToken.token} : params;
    const res = await instance[method](uri, options);

    if (!res) {
      throw new Error('UNKNOWN');
    }

    if (res.data && res.data.status === 'SUCCESS') {
    	return res.data.data;
    } else {
    	throw new Error(res.data ? res.data : 'UNKNOWN');
    }
  } catch (error) {
    if (error.code === "ERR_CANCELED") return null;

    const obj = error?.response?.data;

  	if (obj?.errorCode === 'UNAUTHORIZED' && url != '/login') {
  		return location.href = '/login';
  	} else if (obj?.errorCode) {
      if (shouldShowToast) {
        toast.error(language.errors[obj.errorCode] || obj.message);
        return false;
  		}

      return obj.errorCode;
  	}

    toast.error(language.errors[errors.UNKNOWN]);
    return false;
  }
}

export const buildQuery = (url, params) => {
	if (!params) return url;

	const keys = Object.keys(params);
	let query = '';
	
	for (const key of keys) {
		query += query ? '&' : '?';
		query += `${key}=${params[key]}`;
	}

	return url + query;
}