<script setup>
import { ref, defineProps, inject } from "vue";
import * as _ from 'lodash';

const l = inject('L');
const language = (text) => {
  return l.executiveInfo.tabs[_.camelCase(text)];
};

import GeneralInfo from './GeneralInfo/Index';
import ExperienceAndCertification from './ExperienceCertification/Index';
import StrengthCharacteristic from './StrengthCharacteristic/Index';
import Compensation from './Compensation/Index';
import Evaluation from './Evaluation/Index';

defineProps({
  generalInfo: Object,
});

const tabs = ref([{
  index: 1,
  title: 'General Info',
  id: 'tab-general-info'
}, {
  index: 2,
  title: 'Experience And Certifications',
  id: 'tab-exp-cert'
}, {
  index: 3,
  title: 'Strength And Characteristics',
  id: 'tab-str-char'
}, {
  index: 4,
  title: 'Evaluation',
  id: 'tab-evaluation'
}, {
  index: 5,
  title: 'Compensation',
  id: 'tab-compensation'
}]);
const tab = ref(1);

const changetab = (index) => {
  tab.value = index;
};
</script>
<template>
  <div class="box-tab">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li
        v-for="item in tabs"
        class="nav-item"
        role="presentation"
        :key="item.index"
      >
        <button
          @click="changetab(item.index)"
          :class="tab === item.index ? 'active text-bold' : ''"
          class="nav-link text-bold"
          type="button">
            {{ language(item.title) }}
        </button>
      </li>
    </ul>
  </div>
  <div class="tab-content" id="myTabContent">
    <div
      v-for="item in tabs"
      :key="item.index"
      class="tab-pane fade content-tab"
      :class="{
        'show active': tab === item.index,
        'height-auto': tab === 2 || tab === 3
      }"
    >
      <GeneralInfo :general-info="generalInfo" v-if="item.index === 1" />
      <ExperienceAndCertification
        :general-info="generalInfo"
        v-if="item.index === 2"
      />
      <StrengthCharacteristic
        :general-info="generalInfo"
        v-if="item.index === 3"
      />

      <Evaluation v-if="item.index === 4" />
      <Compensation v-if="item.index === 5" />
    </div>
  </div>
</template>
