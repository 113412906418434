<script setup>
import { inject, computed, defineEmits, ref } from 'vue';
import * as _ from 'lodash';
import DatePicker from '@/components/DatePicker';

const l = inject('L');
const emits = defineEmits(['submitForm']);

const props = defineProps({
  data: {
    famRelation: String,
    famNm: String,
    famJob: String,
    famBirthDt: String,
  },
})

const form = computed(() => ({ ...props.data }));

const isSubmit = ref(false);
const errors = ref({});

const validateForm = () => {
  const obj = form.value;

  for (const key of Object.keys(obj)) {
    if (errors.value[key]) {
      errors.value[key] = !obj[key];
    }
  }
};

const handleSubmit = async () => {
  isSubmit.value = true;
  validateForm();
  
  const checkError = Object.values(errors.value).some(
    (value) => value === true
  );

  if (checkError) {
    return;
  }

  emits('submitForm', form.value);
};

const language = (text, isCommon) => {
  if (isCommon) return l[_.camelCase(text)];
  return l.executiveInfo.forms.family[_.camelCase(text)];
};

defineExpose({
  handleSubmit
});
</script>
<template>
  <form @submit.prevent="handleSubmit">
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="relationshipType" class="form-label">{{
          language('Relationship Type')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="form.famRelation"
          id="relationshipType"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="20"
        />
        <em class="red text-xs" v-if="isSubmit && errors.famRelation">{{ language('This is a required field') }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="Name" class="form-label">{{
          language('Name')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="form.famNm"
          id="Name"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="50"
        />
        <em class="red text-xs" v-if="isSubmit && errors.famNm">{{ language('This is a required field') }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="Job" class="form-label">{{
          language('Job')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="form.famJob"
          id="Job"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="20"
        />
        <em class="red text-xs" v-if="isSubmit && errors.famJob">{{ language('This is a required field') }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="Job" class="form-label">{{
          language('Date of birth')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <DatePicker v-model="form.famBirthDt" @change="validateForm" />
        <em class="red text-xs" v-if="isSubmit && errors.famBirthDt">{{ language('This is a required field') }}</em>
      </div>
    </div>
  </form>
</template>
