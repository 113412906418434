import { callApi } from '../index';

export const listMaster = async (params) => {
  return await callApi('get', '/master-code', params);
};
export const deleteMaster = async (id) => {
  return await callApi('delete', `/master-code/${id}`);
};

export const createMaster = async (params) => {
  return await callApi('post', `/master-code`, params);
};

export const editMaster = async (id, params) => {
  return await callApi('put', `/master-code/${id}`, params);
};

