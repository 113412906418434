import axiosInstance from '@/network/apiAuth';

export const uploadImage = async (formData) => {
  try {
    const response = await axiosInstance.post('/media', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data.data;
  } catch (error) {
    return null;
  }
}