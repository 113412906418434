<script setup>
import { inject, ref, computed } from "vue";
import * as _ from 'lodash';
import { toast } from 'vue3-toastify';
import { v4 as uuidv4 } from 'uuid';

import AddEditFamily from '@/views/components/executives/GeneralInfo/Family/AddEdit';
import AddEditEducation from '@/views/components/executives/GeneralInfo/Education/AddEdit';
import AddEditNetworking from '@/views/components/executives/GeneralInfo/Network/AddEdit';
import AddEditCertification from '@/views/components/executives/ExperienceCertification/Certification/AddEdit';
import AddEditExperience from '@/views/components/executives/ExperienceCertification/Experience/AddEdit';
import AddEditMaster from '@/views/components/masters/AddEditMaster';
import AddEditSubMaster from '@/views/components/masters/AddEditSubMaster';
import AddEditCompany from '@/views/components/companies/AddEdit';
import AddEditCompensation from '@/views/components/executives/Compensation/AddEdit';
import AddEvaluationYear from '@/views/components/executives/Evaluation/AddYear';
import AddEditEvaluationQuarter from '@/views/components/executives/Evaluation/AddEditQuarter';
import AddEditEvaluationFinal from '@/views/components/executives/Evaluation/AddEditFinal';

const wSize  = computed(() => window.innerWidth);
const l = inject('L');
const language = (text, isModal) => {
  if (isModal) {
    return l.modal[_.camelCase(text)];
  }

  return l.executiveInfo.sections[_.camelCase(text)];
};

const props = defineProps({
  size: String,
  title: String,
  type: String,
  refId: Number,
  parentId: String,
  addEditFn: Function,
  data: Object,
  isAdd: Boolean,
  options: Object,
});

const close = ref(null);
const show = ref(false);
const form = ref(null);
const loading = ref(false);

const submitForm = () => {
  loading.value = true;
  form.value.handleSubmit();

  setTimeout(() => loading.value = false, 400)
};

const handleFormSubmit = async (formData) => {
  const params = { ...formData };

  if (props.parentId) {
    params.empId = props.parentId;
  }

  let res;

  if (!props.refId) {
    res = await props.addEditFn(params);
  } else {
    res = await props.addEditFn(params, props.refId);
  }

  if (!res) return;

  close.value.click();

  toast.success(language('Save successfully', true));

  if (props.options && props.options.refreshData) {
    if (props.type === 'EVALUATION_QUARTER' || props.type === 'EVALUATION_FINAL') {
      setTimeout(() => props.options.refreshData(props.options.kpiId, props.type === 'EVALUATION_FINAL' ? false : true), 300);
    } else {
      setTimeout(() => props.options.refreshData(), 300);
    }
  }
};

const uid = uuidv4();

const refresh = () => {
  setTimeout(() => show.value = false, 500);
};

const buttonText = (type) => {
  switch (type) {
    case 'FAMILY':
      return language('Add Family Relationship');
    case 'EDUCATION':
      return language('Add Education');
    case 'NETWORK':
      return language('Add Networking');
    case 'CERT':
      return language('Add Certification');
    case 'EXPE':
      return language('Add Working Experience');
    case 'MASTER':
      return language('Add Master Code', true);
    case 'SUBMASTER':
      return language('Add Sub Code', true);
    case 'COMPANY':
      return language('Add Company', true);
    case 'SALARY':
      return language('Add Compensation ');
    case 'EVALUATION_QUARTER':
      return language('Add Quarterly Achievement');
    case 'EVALUATION_YEAR':
      return language('Add Evaluation Year');
    case 'EVALUATION_FINAL':
      return language('Add Final Evaluation');
    default:
      break;
  }
};

const titleText = (type) => {
  switch (type) {
    case 'FAMILY':
      return language('Family Relationship Detail');
    case 'EDUCATION':
      return language('Educational History');
    case 'NETWORK':
      return language('Networking Detail');
    case 'CERT':
      return language('Certification Detail');
    case 'EXPE':
      return language('Experience Detail');
    case 'MASTER':
      return language('Master Code Detail', true);
    case 'SUBMASTER':
      return language('Sub Code Detail', true);
    case 'COMPANY':
      return language('Company Detail', true);
    case 'SALARY':
      return language('Compensation Detail');
    case 'EVALUATION_QUARTER':
      return language('Quarterly Achievement Detail');
    case 'EVALUATION_YEAR':
      return language('Evaluation Year Detail');
    case 'EVALUATION_FINAL':
      return language('Final Evaluation Detail');
    default:
      break;
  }
};
</script>
<template>
  <button class="btn btn-sm mb-0 mx-1" :class="isAdd ? 'p-2 pt-1 pb-1 btn-outline-secondary' : 'btn-icon-only btn-primary'" @click="show = true" data-bs-toggle="modal" :data-bs-target="`#modal-${uid}`">
    <i class="far fa-edit" v-if="!isAdd"></i>
    <i class="fas fa-plus" v-if="isAdd"></i> {{ (isAdd && wSize > 767) || (isAdd && options?.showMobile) ? buttonText(type) : '' }}
  </button>
  <div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" :id="`modal-${uid}`" tabindex="-1" aria-labelledby="editModelLabel" aria-hidden="true">
    <div class="modal-dialog" :class="`modal-${size}`">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ titleText(type) }}</h5>
          <button type="button" class="btn-close btn-sm" ref="close" @click="refresh" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" v-if="show">
          <AddEditFamily v-if="type === 'FAMILY'" ref="form" @submitForm="handleFormSubmit" :data="data" :options="options" />
          <AddEditEducation v-if="type === 'EDUCATION'" ref="form" @submitForm="handleFormSubmit" :data="data" :options="options" />
          <AddEditNetworking v-if="type === 'NETWORK'" ref="form" @submitForm="handleFormSubmit" :data="data" :options="options" />
          <AddEditCertification v-if="type === 'CERT'" ref="form" @submitForm="handleFormSubmit" :data="data" :options="options" />
          <AddEditExperience v-if="type === 'EXPE'" ref="form" @submitForm="handleFormSubmit" :data="data" :options="options" />
          <AddEditMaster v-if="type === 'MASTER'" ref="form" @submitForm="handleFormSubmit" :data="data" :options="options" />
          <AddEditSubMaster v-if="type === 'SUBMASTER'" ref="form" @submitForm="handleFormSubmit" :data="data" :options="options" />
          <AddEditCompany v-if="type === 'COMPANY'" ref="form" @submitForm="handleFormSubmit" :data="data" :options="options" />
          <AddEditCompensation v-if="type === 'SALARY'" ref="form" @submitForm="handleFormSubmit" :data="data" :options="options" />
          <AddEvaluationYear v-if="type === 'EVALUATION_YEAR'" ref="form" @submitForm="handleFormSubmit" :data="data" :options="options" />
          <AddEditEvaluationQuarter v-if="type === 'EVALUATION_QUARTER'" ref="form" @submitForm="handleFormSubmit" :data="data" :options="options" />
          <AddEditEvaluationFinal v-if="type === 'EVALUATION_FINAL'" ref="form" @submitForm="handleFormSubmit" :data="data" :options="options" />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="refresh" data-bs-dismiss="modal"><i class="far fa-times-circle"></i> {{ language('Cancel', true) }}</button>
          <button type="button" class="btn btn-success" @click="submitForm()" :disabled="loading">
            <div class="spinner-border text-light spinner-border-sm" v-if="loading" role="status"></div>
            <i class="far fa-save" v-if="!loading"></i>
            {{ language('Save', true) }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
