<script setup>
import { ref, onMounted, computed, inject, watch } from 'vue';
import Table from '@/components/Table';
import Loading from '@/components/Loading';
import NoResult from '@/components/NoResult';
import { executivesList, deleteExecutive } from '@/network/apis/executives';
import { getCompanies } from "@/network/apis/companies";
import { useRouter } from 'vue-router';
import { toast } from 'vue3-toastify';
import * as _ from 'lodash';
import { useMainStore } from '@/store/pinia';

import { access } from '@/ultis';

const size  = computed(() => window.innerWidth);
const store = useMainStore();
const accessCode  = computed(() => parseInt(store.auth?.user?.sub_code.value));
const Config = inject('Config');
const l = inject('L');
const router = useRouter();
const params = ref({
  limit: Config.limit,
  page: 1,
});
const loading = ref(true);
const executives = ref([]);
const actions = ref(['D']);
const totalExct = ref(0);
const companies = ref([]);
const company = ref('ALL');
const ignorekeys = ref(['id', 'regDt', 'companyId']);
const searchBy = ref([
  { label: '이름', value: 'Name' },
  { label: '소속', value: 'Department' },
  { label: '핸드폰', value: 'Phone Number' },
  { label: '이메일', value: 'Email' },
  { label: '직위', value: 'Job Title' },
  { label: '사번', value: 'Employee Number' },
]);
const searchField = ref('Name');
const keyword = ref('');
const redirects = ref(['/executives/company/:companyId', '/executive/:id']);

const fetchExecutives = async () => {
  loading.value = true;

  const res = await executivesList(params.value);
  if (!res) return;

  executives.value = res.employees;
  totalExct.value = res.itemCount;

  setTimeout(() => {
    loading.value = false;
  }, 500);
};

const fetchCompanies = async () => {
  const res = await getCompanies({ limit: 10000 });
  const companiesDropdown = res.companies.map((company) => {
    return {
      value: company.id,
      text: company.companyName,
    };
  });

  companies.value = [
    {
      value: 'ALL',
      text: '전체',
    },
    ...companiesDropdown,
  ];
};

onMounted(() => {
  fetchCompanies();
  fetchExecutives();
});

const pageTotal = computed(() => {
  return totalExct.value % Config.limit > 0
    ? parseInt(totalExct.value / Config.limit) + 1
    : totalExct.value / Config.limit;
});

const pageFn = (index) => {
  params.value.page = index;
};

const viewFn = (item) => {
  return router.push(`/executive/${item.id}`);
};

const delFn = async (id) => {
  await deleteExecutive(id);
  toast.success(language('Deleted successfully'));
  fetchExecutives();
};

const handleSearch = (e) => {
  if (!keyword.value && e.which != 8) return;

  params.value = {
    ...params.value,
    page: 1,
    searchBy: _.camelCase(searchField.value),
    keyword: keyword.value,
    companyId: company.value,
  };
};

const isNoResult = computed(() => {
  return _.size(executives.value) === 0 && !loading.value;
});

watch(
  params,
  () => {
    fetchExecutives();
  },
  { deep: true }
);

const language = (text) => {
  return l[_.camelCase(text)];
};

const goToCreate = () => {
  router.push('/executive-create');
};

</script>

<template>
  <div class="card mx-4">
    <div class="d-flex justify-content-between">
      <div class="filter-box p-4 row pb-0 w-75">
        <div class="col-md-4 col-xl-3 col-xxl-2">
          <label for="selectCompany" class="form-label">{{
            language('Select Company')
          }}</label>
          <select
            class="form-select"
            v-model="company"
            @change="handleSearch"
          >
            <option v-for="(item, i) in companies" :value="item.value" :key="i">
              {{ item.text }}
            </option>
          </select>
        </div>
        <div class="col-xs-12 col-md-4 col-xl-3 col-xxl-2">
          <label for="exampleFormControlInput1" class="form-label">{{
            language('Search by')
          }}</label>
          <select
            class="form-select"
            v-model="searchField"
            @change="handleSearch"
          >
            <option v-for="(item, i) in searchBy" :value="item.value" :key="i">
              {{ item.label }}
            </option>
          </select>
        </div>
        <div class="col-md-5 col-xl-4 col-xxl-3">
          <label for="KeywordInput" class="form-label">{{
            language('Keyword')
          }}</label>
          <input
            type="email"
            class="form-control"
            v-model="keyword"
            id="KeywordInput"
            @keyup="handleSearch"
            :placeholder="language('Enter keyword')"
          />
        </div>
      </div>
      <div class="w-25 align-items-center p-4 mt-4 d-flex justify-content-end">
        <button v-if="access(accessCode, ['MANAGER', 'ADMIN'])" class="btn btn-sm mb-0 mx-1 p-2 pt-1 pb-1 btn-outline-secondary" @click="goToCreate"><i class="fas fa-plus"></i>  {{ size > 767 ? language('addNewExecutive') : '' }}</button>
      </div>
    </div>

    <hr class="mb-0 mt-4" />
    <div
      class="d-flex justify-content-end align-items-center px-4 pt-2 font-weight-bold"
    >
      {{ language('total') }}: {{ totalExct }}
    </div>
    <div class="card-body px-0 pt-0 pb-2 content-box">
      <Loading v-if="loading" />
      <NoResult v-if="isNoResult" />
      <Table
        v-if="!loading && !isNoResult"
        :data="executives"
        :ignorekeys="ignorekeys"
        :pageTotal="pageTotal"
        :isNo="true"
        :actions="actions"
        :pageFn="pageFn"
        :viewFn="viewFn"
        :delFn="delFn"
        :redirects="redirects"
        :page="params.page"
        screen="executiveList"
      />
    </div>
  </div>
</template>
