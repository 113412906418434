import { callApi } from '../index';

export const listCert = async (params) => {
  return await callApi('get', `/liscense`,params);
};

export const deteleCert = async (id) => {
  const res = await callApi('delete', `/liscense/${id}`);
  return res;
};

export const createCert = async (params) => {
  return await callApi('post', `/liscense`, params);
};

export const editCert = async (id, params) => {
  return await callApi('put', `/liscense/${id}`, params);
};
