<script setup>
import { inject, computed, defineEmits, ref } from 'vue';
import * as _ from 'lodash';
import YearPicker from '@/components/YearPicker';

const l = inject('L');
const emits = defineEmits(['submitForm']);

const props = defineProps({
  data: {
    salYear: String,
    salAnnual: String,
    salIncentive: String,
    salNote: String
  },
})

const form = computed(() => ({ ...props.data }));

const isSubmit = ref(false);
const errors = ref({});

const validateForm = () => {
  const obj = form.value;

  for (const key of Object.keys(obj)) {
    if (errors.value[key]) {
      errors.value[key] = !obj[key];
    }
  }
};

const handleSubmit = async () => {
  isSubmit.value = true;
  validateForm();
  
  const checkError = Object.values(errors.value).some(
    (value) => value === true
  );

  if (checkError) {
    return;
  }

  const obj = _.cloneDeep(form.value);
  obj.salYear = obj.salYear.toString();

  emits('submitForm', obj);
};

const language = (text, isCommon) => {
  if (isCommon) return l[_.camelCase(text)];

  return l.executiveInfo.forms.compensation[_.camelCase(text)];
};

defineExpose({
  handleSubmit
});
</script>
<template>
  <form @submit.prevent="handleSubmit">
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="Job" class="form-label">{{
          language('Year')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <YearPicker v-model="form.salYear" @change="validateForm" :max-current="true" />
        <em class="red text-xs" v-if="isSubmit && errors.salYear">{{ language('This is a required field') }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="annualSalary" class="form-label">{{
          language('Annual salary')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="number"
          class="form-control"
          v-model="form.salAnnual"
          id="annualSalary"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
        />
        <em class="red text-xs" v-if="isSubmit && errors.salAnnual">{{ language('This is a required field') }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="Incentive" class="form-label">{{
          language('Incentive')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="number"
          class="form-control"
          v-model="form.salIncentive"
          id="Incentive"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
        />
        <em class="red text-xs" v-if="isSubmit && errors.salIncentive">{{ language('This is a required field') }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="Note" class="form-label">{{
          language('Note')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <textarea
          :placeholder="language('Enter your text', true)"
          rows="5"
          class="form-control"
          v-model="form.salNote"
          @input="validateForm"
        />
        <em class="red text-xs" v-if="isSubmit && errors.salNote">{{ language('This is a required field') }}</em>
      </div>
    </div>
  </form>
</template>
