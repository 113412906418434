<script setup>
import { ref, defineEmits, inject } from 'vue';
import * as _ from 'lodash';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

import { uploadImage } from '@/network/apis/media';
import LoadingWindow from './LoadingWindow';


const l = inject('L');

const language = (text) => {
  return l[_.camelCase(text)];
};

const emits = defineEmits(['uploaded']);
const imageSrc = ref('');
const croppedImage = ref('');
let cropper = null;
const fileInput = ref(null);
const loading = ref(false);

const stencilProps = ref({
  aspectRatio: 3/4,
  handlers: {
    move: true,
    resize: true,
    rotate: false,
  },
  movable: true,
  resizable: true,
});

function triggerFileInput() {
  fileInput.value.click();
}

function onFileChange(event) {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      imageSrc.value = e.target.result;
    };
    reader.readAsDataURL(file);
  }
}

function onCropChange(newCropper) {
  cropper = newCropper;
}

function getCroppedImage() {
  if (cropper) {
    croppedImage.value = cropper.canvas.toDataURL('image/png');
  }
}

function dataURItoBlob(dataURI) {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
}

async function uploadCroppedImage() {
  loading.value = true;

  getCroppedImage()

  if (croppedImage.value) {
    const blob = dataURItoBlob(croppedImage.value);
    const formData = new FormData();
    formData.append('file', blob, `cropped-avatar-${Date.now()}.png`);

    const res = await uploadImage(formData);

    if (!res) {
      setTimeout(() => loading.value = false, 1000)
      return;
    }

    emits('uploaded', res);
    setTimeout(() => loading.value = false, 1000)
  } else {
    setTimeout(() => loading.value = false, 1000)
  }
}
</script>

<template>
  <LoadingWindow v-if="loading" />
  <div>
    <div class="d-flex justify-content-center">
      <button type="button" class="btn btn-default mb-0" @click="triggerFileInput">
        <i class="fas fa-file-image"></i> {{ language('Select Image') }}
      </button>
      <input type="file" ref="fileInput" @change="onFileChange" style="display: none;" />
    </div>
    <Cropper
      v-if="imageSrc"
      :src="imageSrc"
      :stencil-props="stencilProps"
      @change="onCropChange"
      class="mt-4"
    />
    <div v-if="imageSrc" class="mt-4 d-flex justify-content-center">
      <button type="button" class="btn btn-success" @click="uploadCroppedImage"><i class="fas fa-upload"></i> {{ language('Upload') }}</button>
    </div>
  </div>
</template>