<script setup>
import { inject, computed, defineEmits, ref } from 'vue';
import * as _ from 'lodash';
import { compareDate } from '@/ultis';
import DatePicker from '@/components/DatePicker';

const l = inject('L');
const emits = defineEmits(['submitForm']);

const props = defineProps({
  data: {
    eduEtype: String,
    eduNm: String,
    eduMajor: String,
    eduMajorSub: String,
    eduEdt: String,
    eduSdt: String,
  },
})

const form = computed(() => ({ ...props.data }));

const isSubmit = ref(false);
const errors = ref({
  sdtBeforeEdt: false,
});

const validateForm = () => {
  const obj = form.value;

  for (const key of Object.keys(obj)) {
    if (key === 'eduEdt') {
      errors.value.sdtBeforeEdt = compareDate(form.value.eduEdt, form.value.eduSdt);
    }
  }
};

const handleSubmit = async () => {
  isSubmit.value = true;
  validateForm();
  
  const checkError = Object.values(errors.value).some(
    (value) => value === true
  );

  if (checkError) {
    return;
  }

  emits('submitForm', form.value);
};

const language = (text, isCommon) => {
  if (isCommon) return l[_.camelCase(text)];

  return l.executiveInfo.forms.education[_.camelCase(text)];
};

defineExpose({
  handleSubmit
});
</script>
<template>
  <form @submit.prevent="handleSubmit">
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="Degree" class="form-label">{{
          language('Degree')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="form.eduEtype"
          id="Degree"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="20"
        />
        <em class="red text-xs" v-if="isSubmit && errors.eduEtype">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="InstitutionName" class="form-label">{{
          language('Institution name')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="form.eduNm"
          id="InstitutionName"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="100"
        />
        <em class="red text-xs" v-if="isSubmit && errors.eduNm">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="Major" class="form-label">{{
          language('Major')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="form.eduMajor"
          id="Major"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="100"
        />
        <em class="red text-xs" v-if="isSubmit && errors.eduMajor">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="SecondMajor" class="form-label">{{
          language('Second Major')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="form.eduMajorSub"
          id="SecondMajor"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="100"
        />
        <em class="red text-xs" v-if="isSubmit && errors.eduMajorSub">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="AdmissionDate" class="form-label">{{
          language('Admission date')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <DatePicker v-model="form.eduSdt" @change="validateForm" />
        <em class="red text-xs" v-if="isSubmit && errors.eduSdt">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="GraduationDate" class="form-label">{{
          language('Graduation date')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <DatePicker v-model="form.eduEdt" @change="validateForm" />
        <em class="red text-xs" v-if="isSubmit && errors.eduEdt">{{ language('This is a required field', true) }}</em>
        <em class="red text-xs" v-if="isSubmit && errors.sdtBeforeEdt">{{ language('The admission date must be earlier than the graduation date', true) }}</em>
      </div>
    </div>
  </form>
</template>
