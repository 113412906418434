import moment from 'moment';
import Cookies from 'js-cookie';
import * as _ from 'lodash';
import Config from '../config';

export const date = (text) => {
	if (!text) return '';
	
  return moment(text).format('YYYY-MM-DD');
}

export const datetime = (text) => {
	if (!text) return '';
	
  return moment(text).format('YYYY-MM-DD HH:mm');
}

export const compareDate = (date1, date2) => {
	if (!date1 || !date2) return false;

  return moment(date1).isBefore(moment(date2));
}

export const access = (code, roles = ['USER']) => {
	const permissions = Config.permissions;

	if (_.indexOf(roles, 'USER') != -1) {
		return code >= permissions.USER;
	} else if (_.indexOf(roles, 'MANAGER') != -1) {
		return code >= permissions.MANAGER;
	} else {
		return code >= permissions.ADMIN
	}
}

export const randomColor = (length, a) => {
	const arr = [];

	if (length === 0) return null;

	for (let i = 0; i < length; i++) {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);

    const hexR = r.toString(16).padStart(2, '0');
    const hexG = g.toString(16).padStart(2, '0');
    const hexB = b.toString(16).padStart(2, '0');

    if (a) {
    	arr.push(`rgba(${r},${g},${b},${a})`);
    } else {
    	arr.push(`#${hexR}${hexG}${hexB}`);
    }
	}

	if (length === 1) return arr[0];

	return arr;
}

export const setCookie = (key, data, ex) => {
	Cookies.set(key, JSON.stringify(data), { expires: moment().add(ex, 'minutes').toDate(), secure: true })
}

export const getCookie = (key) => {
	const val = Cookies.get(key);

	if (!val) return undefined;

	return JSON.parse(Cookies.get(key));
}

export const removeCookie = (key) => {
	const val = Cookies.get(key);

	if (!val) return;

	Cookies.remove(key);
}
