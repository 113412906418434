export default {
  //Login Menu
  sideBar: {
    companyOverview: '임원정보',
    overview: '임원현황',
    executiveList: '임원목록',
    favorites: '즐겨찾기',
    system: '시스템관리',
    masterData: '마스터코드',
    subMasterData: '서브코드',
    companies: '가족사 관리',
    accessLog: '로그조회',
  },

  route: {
    overview: '임원 현황',
    executives: '임원정보',
    executive: '임원',
    company: '가족사',
    favorite: '즐겨찾기',
    masters: '마스터코드',
    subMasters: '서브코드',
    executiveCreate: '임원 기본정보',
    executiveEdit: '임원 기본정보',
    companies: '가족사 관리',
    log: '접근 로그'
  },
  //LoginPage
  loginPageLabel: 'KG임원 인사정보시스템',
  login: '로그인',
  mustGreaterThanOrEqualTo6Characters:
    '유효한 유저네임과 비밀번호를 입력해주세요.',
  theAdmissionDateMustBeEarlierThanTheGraduationDate:
    '입학일은 졸업일보다 이전이어야 합니다.',
  toDateMustBeGreaterThanFromDate: '종료 날짜는 시작 날짜보다 켜야 합니다.',
  userName: '사용자 이름',
  password: '비밀번호',
  //Common Modal Delete
  deleteConfirm: '확인',
  cancel: '취소',
  modalTitle: '알림',
  deleteTitle: '이 항목을 삭제하시겠습니까?',
  areYouSure: '로그아웃?',
  deleteCannotRevert: '항목이 삭제되면 복원할 수 없습니다',
  youWantToLogoutFromTheSystem: '로그아웃 하시겠습니까?',
  yes: '예',
  //CommonValidation
  thisIsARequiredField: '필수 입력란입니다',
  mustGreaterThanOrEqualTo8Characters: '비밀번호가 8자 이상이어야 합니다.',
  invalidEmail: '유효하지 않은 이메일',
  invalidFileType: '유효하지 않은 파일 형식',
  imageUploadError: '이미지를 업로드 실패했습니다.',
  executiveAdded: '임원이 추가되었습니다.',
  executiveUpdated: '임원 업데이트 되었습니다',
  executiveNotFound: '임원을 못 찾았습니다',
  invalidPhoneNumber: '유효하지 않은 전화번호',
  //Confirm modal overwrite emp_id
  employeeNumberExist: '사번이 이미 존재합니다!',
  contentConfirmOverwrite:
    '사번 {{ empId }}이 이미 존재합니다. 다른 값을 입력하거나 기존 값을 덮어쓰시겠습니까?',
  enterDifferentEmployeeNumber: '다른 사번 입력',
  overwrite: '기존 값 수정',
  from: 'From',
  to: 'To',
  deleteMasterCode: '이 마스터코드를 삭제하시겠습니까? 연결된 서브코드가 있는 경우, 해당 서브코드도 함께 삭제됩니다.',

  //Overview, Dashboard
  overview: '임원현황',
  executives: '임원정보',
  chart: {
    total: '총',
    members: '명',
    companies: '임원 현황',
  },
  table: {
    executiveList: {
      all: '모두',
      name: '이름',
      companyName: '가족사',
      jobTitle: '직위',
      responsibility: '담당부서',
      workplace: '근무지',
      no: 'No',
      department: '소속',
      action: 'Action',
    },
    favoriteList: {
      name: '이름',
      companyName: '가족사',
      jobTitle: '직위',
      responsibility: '소속',
      workplace: '근무지',
      note: '비고',
      no: 'No',
      department: '소속'
    },
    masterCodeList: {
      masterCode: '마스터 코드',
      codeName: '코드명',
      action: 'Action',
      no: 'No',
      orderNum: '정렬 번호',
    },
    subCodeList: {
      masterCode: '마스터 코드',
      subCode: '서브코드',
      subCodeName: '서브코드명',
      value: 'Value',
      action: 'Action',
      no: 'No',
      orderNum: '정렬 번호',
    },
    overview: {
      name: '가족사',
      members: '임원현황',
      modified: '업데이트 날짜',
      no: 'No',
    },
    companyList: {
      companyName: '가족사',
      englishName: '영문명',
      businessCode: '사업자번호',
      workplace: '근무지',
      no: 'No',
      action: 'Action',
    },
    logList: {
      userId: '아이디',
      accessScreen: '접근 화면',
      loginTime: '로그인 시각',
      logoutTime: '로그아웃 시각',
      no: 'No',
    },
  },
  //Executives List

  addNewExecutive: '임원 추가',
  searchBy: '검색 기준',
  keyword: '검색어',
  enterKeyword: '검색어를 입력해주세요.',
  action: 'Action',
  viewInfo: '정보 조회',
  delete: '삭제',
  deletedSuccessfully: '삭제 되었습니다',
  favoriteSuccessfully: '성공적으로 즐겨찾기 했습니다',
  markFavourite: '&lt;$NAME&gt;을(를) 즐겨찾기에 추가하시겠습니까?',
  enterNote: '비고를 입력해주세요.',
  favoriteKeyword: '검색어를 입력해주세요',

  errors: {
    UNAUTHORIZED: '무단',
    UNKNOWN: 'UNKNOWN',
    SOME_THING_WRONG: '다시 시도해주세요!',
    INCORRECT_LOGIN: '잘못된 로그인',
    USER_NOT_FOUND: 'USER_NOT_FOUND',
    EMPLOYEE_ALREADY_EXIST: '제목: 사번가 이미 존재합니다.',
    ORDER_NUMBER_ALREADY_EXIST: '정렬 번호가 이미 존재합니다',
    SUBCODE_ALREADY_EXIST: '서브코드가 이미 존재합니다',
    MASTERCODE_ALREADY_EXIST: '마스터코드가 이미 존재합니다',
    SUBCCODE_VALUE_ALREADY_EXIST:
      '동일한 마스터 코드에 대한 값이 이미 존재합니다.',
    EMPLOYEE_NOT_FOUND: '임원은 존재하지 않습니다',
    EMPLOYEE_KPI_TITLE_ALREADY_EXIST: '해당 년도에 동일한 달성목표가 이미 존재합니다.',
    EMPLOYEE_KPI_MID_ALREADY_EXIST: '해당 년도의 분기 평가가 이미 존재합니다. 다른 분기를 선택해주세요.',
    VALIDATION_ERROR: '잘못된 매개변수'
  },
  logout: '로그아웃',
  welcome: '환영합니다',
  loginSubText: 'KG임원 인사정보시스템에 오신 것을 환영합니다',
  modal: {
    save: '저장',
    close: '닫다',
    cancel: '취소',
    edit: '편집하다',
    add: '추가하다',
    saveSuccessfully: '성공적으로 저장되었습니다!',
    masterCodeDetail: '마스터 코드 상세',
    addMasterCode: '마스터코드 추가',
    addSubCode: '서브코드 추가',
    subCodeDetail: '서브코드 상세',
    addCompany: '가족사 추가',
    companyDetail: '가족사 정보',
  },
  executiveInfo: {
    baseInfo: '기본정보 추가',
    personalInfo: '개인정보',
    excecutiveInfo: '임원정보',
    jobInfo: '사내 정보',
    editInfo: '정보 수정',
    addImage: '이미지 추가',
    general: {
      name: '이름',
      age: '나이',
      phoneNumber: '휴대전화',
      email: '이메일',
      company: '가족사',
      employeeId: '그룹웨어ID',
      jobTitle: '직위',
      responsibility: '직책',
      jobLevel: '직급',
      homeAddress: '주소',
      maritalStatus: '결혼여부',
      weddingDate: '결혼 기념일',
      dateOfBirth: '생년월일',
      postalCode: '우편번호',
      employeeNumber: '사번',
      department: '소속',
      marriageYes: '예',
      marriageNo: 'No',
    },
    sections: {
      familyRelationship: '관계',
      educationalHistory: '학력',
      networkingInfo: '인맥정보',
      addFamilyRelationship: '가족 관계 추가',
      addEducation: '학력 추가',
      addNetworking: '인맥 추가',
      addCertification: '자격추가',
      familyRelationshipDetail: '가족 관계',
      educationDetail: '학력사항',
      networkingDetail: '인맥정보',
      certificationDetail: '자격',
      experienceDetail: '경력',
      addWorkingExperience: '경력 추가',
      addCompensation: '보상 추가',
      compensationDetail: '보상',
      addQuarterlyAchievement: '분기별 성과 추가',
      addFinalEvaluation : '최종 평가 추가',
      addEvaluationYear: '평가 연도 추가',
      evaluationYearDetail: '연도 성과 목표',
      finalEvaluationDetail: '최종평가',
    },
    tabs: {
      generalInfo: '기본정보',
      experienceAndCertifications: '경력, 자격',
      strengthAndCharacteristics: '강점 & 특징',
      evaluation: '평가',
      compensation: '보상',
    },
    forms: {
      family: {
        relationshipType: '가족관계',
        name: '이름',
        job: '직업',
        dateOfBirth: '생년월일',
        thisIsARequiredField: '필수 입력란입니다',
      },
      education: {
        degree: '졸업구분',
        institutionName: '학교명',
        major: '전공명',
        secondMajor: '제2전공명',
        admissionDate: '입학년월',
        graduationDate: '졸업년월',
      },
      network: {
        name: '이름',
        jobTitle: '직위',
        relationship: '관계',
        affiliation: '소속',
        employmentPeriod: '재직기간',
        mainBiography: '주요 약력',
      },
      certification: {
        certification: '자격증명',
        certificationLevel: '등급/점수',
        publisher: '발행처',
        acquisitionDate: '취득일',
      },
      experience: {
        startWorkingDate: '입사일',
        endWorkingDate: '퇴사일',
        establishedDate: '발령일',
        jobTitle: '직위',
        responsibility: '직책',
        jobLevel: '직급',
        company: '근무처',
        department: '부서명',
        workingField: '담당직무',
        employmentType: '고용 형태',
        currentlyWorking: '재직 여부',
      },
      compensation: {
        year: '년도',
        annualSalary: '연봉',
        incentive: '인센티브',
        note: '비고',
      },
      evaluation: {
        indicatorTitle: '달성목표',
        target: '목표',
        quarterlyKpi: '분기별 성과 진행상황',
        finalKpi: '최종 평가',
        quarter: '분기',
        achievementRate: '성과 율',
        detailProgress: '세부 진행상황',
        performanceEvaluation: '성과평가',
        evaluationYear: '평가연도',
        oneStQuarter: '1분기',
        twoStQuarter: '2분기',
        threeStQuarter: '3분기',
        fourStQuarter: '4분기',
        selectQuarter: '분기 선택',
        evaluator: "평가자",
        rate: "달성률",
        grade: "등급",
        detailEvaluation: "평가상세"
      }
    },
    compensation: {
      year: '년도',
      annualSalary: '연봉',
      incentive: '인센티브',
      note: '비고',
      won: '만원',
    }
  },

  // Favorite
  unfavourite: '즐겨찾기 제거',
  favorites: '즐겨찾기',
  areYouSureRemoveFavourite: '<$NAME>을 즐겨찾기에서 제거하시겠습니까?',
  fromFavouriteList: '을(를) 제거하시겠습니까?',
  no: '아니요',
  confirm: '확인',
  asAFavourite: '을 즐겨찾기로 마',
  inputFavoriteNote: '비고를 입력하세요',
  //Experience & Certification
  workingExperience: '경력',
  certification: '자격',
  addWorkingExperience: ' 경력 추가',
  addCertification: '자격추가',
  currentlyWorking: '재직 여부',

  //Strength & Characteristic
  placeholderInputStrength: '강점을 입력해주세요',
  placeholderInputHobby: '특기를 입력해주세요.',
  strength: '강점',
  characteristic: '취미 및 특기',
  hobby: '취미',
  specialSkills: '특기',
  enterYourText: '텍스트를 입력하세요',

  //Master code
  masterData: '마스터 데이터 목록',
  addSubCode: '서브코드 추가',
  addMasterCode: '마스터코드 추가',
  total: '총',
  forms: {
    masters: {
      masterCode: '마스터 코드',
      codeName: '코드명',
      orderNumber: '정렬번호',
    },
    subMasters: {
      masterCode: '마스터 코드',
      subCode: '서브코드',
      name: '서브코드명',
      value: 'Value',
      orderNumber: '정렬번호',
    },
    companies: {
      companyName: '가족사명',
      englishName: '영문명',
      businessCode: '사업자번호',
      address: '주소',
      workplace: '근무지',
      establishedDate: '설립일',
      sortNumber: '정렬 번호',
    },
  },
  addCompany: '회사 추가',

  company: {
    companyName: 'Company Name',
    englishName: 'English Name',
    businessCode: 'Business code',
    address: 'Address',
    workplace: 'Workplace',
    establishedDate: 'Established date ',
    sortNumber: 'Sort Number ',
    companyDetail: 'Company Detail ',
  },
  goToDashboard: '대시보드로 이동',
  noData: 'No Data',
  upload: '업로드',
  selectImage: '이미지 선택',
  uploadImage: '이미지 업로드',
  changeAvatar: '이미지 변경',
  selectDate: '날짜 선택',
  selectYear: '연도 선택',
  selectCompany: '가족사',
  postCodeValid: '우편번호는 5자리여야 합니다'
};
