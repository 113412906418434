<script setup>
import { ref, inject } from 'vue';
import Characteristic from './Characteristic';
import Strength from './Strength';
const l = inject('L');
import * as _ from 'lodash';

const tabs = ref([
  {
    index: 1,
    title: 'Strength',
    id: 'tab-characteristic',
  },
  {
    index: 2,
    title: 'Characteristic',
    id: 'tab-strength',
  },
]);
const tab = ref(1);

const language = (text) => {
  return l[_.camelCase(text)];
};

const changetab = (index) => {
  tab.value = index;
};
</script>
<template>
  <div class="row mt-4">
    <div class="d-flex justify-content-center gap-2" id="tab" role="">
      <div v-for="item in tabs" class="" role="" :key="item.index">
        <button
          @click="changetab(item.index)"
          :class="
            tab === item.index
              ? 'active bg-primary text-white  text-decoration'
              : ''
          "
          class="border-0 text-black rounded p-2"
          type="button"
        >
          {{ language(item.title) }}
        </button>
      </div>
    </div>
    <div class="tab-content" id="tabContent">
      <div
        v-for="item in tabs"
        :key="item.index"
        class="tab-pane fade content-tab"
        :class="tab === item.index ? 'show active' : ''"
      >
        <Strength v-if="item.index === 1" />
        <Characteristic v-if="item.index === 2" />
      </div>
    </div>
  </div>
</template>
