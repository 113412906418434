import { callApi } from '../index';

export const executivesList = async (params) => {
  return await callApi('get', '/employee', params);
};

export const deleteExecutive = async (id) => {
  return await callApi('delete', `/employee/${id}`);
};

export const getCompanyExecutive = async (params) => {
  return await callApi('get', 'employee/list/company', params);
};

export const getFavoriteExecutive = async (params) => {
  return await callApi('get', 'employee/list/bookmark', params);
};
export const executive = async (id) => {
  return await callApi('get', `/employee/${id}`);
};

export const getFamilies = async (id, params) => {
  return await callApi('get', `/family`, params);
};

export const deleteFamily = async (id) => {
  return await callApi('delete', `/family/${id}`);
};

export const createFamily = async (params) => {
  return await callApi('post', `/family`, params);
};

export const editFamily = async (id, params) => {
  return await callApi('put', `/family/${id}`, params);
};

export const getEducations = async (id, params) => {
  return await callApi('get', `/education`, params);
};

export const deleteEducation = async (id) => {
  return await callApi('delete', `/education/${id}`);
};

export const createEducation = async (params) => {
  return await callApi('post', `/education`, params);
};

export const editEducation = async (id, params) => {
  return await callApi('put', `/education/${id}`, params);
};

export const getNetworks = async (id, params) => {
  return await callApi('get', `/network`, params);
};

export const deleteNetwork = async (id) => {
  return await callApi('delete', `/network/${id}`);
};

export const createNetwork = async (params) => {
  return await callApi('post', `/network`, params);
};

export const editNetwork = async (id, params) => {
  return await callApi('put', `/network/${id}`, params);
};

export const createExecutive = async (data, options) => {
  return await callApi('post', '/employee', data, options);
}

export const updateExecutive = async (id, data) => {
  return await callApi('put', `/employee/${id}`, data);
}

export const patchUpdateExecutive = async (id, data) => {
  return await callApi('patch', `/employee/${id}`, data);
}