export default {
  limit: 10,
  girdLimit: 12,
  permissions: {
  	USER: 0,
  	MANAGER: 20,
  	ADMIN: 99
  }
};

export const IMAGE_EXTENSIONS = ['image/jpeg', 'image/png', 'image/jpg'];

export const SAVE_TYPE = {
  INLINE: 'Inline',
  FILE: 'File',
  IMAGE: 'Image'
}

export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';