<script setup>
import { inject, computed, defineEmits, ref } from 'vue';
import * as _ from 'lodash';

const l = inject('L');
const emits = defineEmits(['submitForm']);

const props = defineProps({
  data: {
    mstCode: String,
    subCode: String,
    subNm: String,
    value: String,
    orderNum: Number,
  },
  options: Object,
})

const form = computed(() => ({ ...props.data }));

const isSubmit = ref(false);
const errors = ref({
  mstCode: true,
  subCode: true,
  subNm: true,
  value: true,
});

const validateForm = () => {
  const obj = form.value;

  for (const key of Object.keys(obj)) {
    if (errors.value[key]) {
      errors.value[key] = !obj[key];
    }
  }
};

const handleSubmit = async () => {
  isSubmit.value = true;
  validateForm();
  
  const checkError = Object.values(errors.value).some(
    (value) => value === true
  );

  if (checkError) {
    return;
  }

  emits('submitForm', form.value);
};

const language = (text, isCommon) => {
  if (isCommon) return l[_.camelCase(text)];
  
  return l.forms.subMasters[_.camelCase(text)];
};

defineExpose({
  handleSubmit
});
</script>
<template>
  <form @submit.prevent="handleSubmit">
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4 text-start">
        <label for="MasterCode" class="form-label">{{
          language('Master Code')
        }}<em class="red">*</em></label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <select
          class="form-select"
          v-model="form.mstCode"
          :disabled="!options?.isAdd"
        >
          <option v-for="(item, i) in options.masters" :value="item.value" :key="i">
            {{ item.label }}
          </option>
        </select>
        <em class="red text-xs" v-if="isSubmit && errors.mstCode">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4 text-start">
        <label for="SubCode" class="form-label">{{
          language('Sub Code')
        }}<em class="red">*</em></label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="form.subCode"
          id="SubCode"
          :placeholder="language('Enter your text', true)"
          :disabled="!options?.isAdd"
          @input="validateForm"
          maxlength="100"
        />
        <em class="red text-xs" v-if="isSubmit && errors.subCode">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4 text-start">
        <label for="Name" class="form-label">{{
          language('Name')
        }}<em class="red">*</em></label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="form.subNm"
          id="Name"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="100"
        />
        <em class="red text-xs" v-if="isSubmit && errors.subNm">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4 text-start">
        <label for="Value" class="form-label">{{
          language('Value')
        }}<em class="red">*</em></label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="form.value"
          id="Value"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
        />
        <em class="red text-xs" v-if="isSubmit && errors.value">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4 text-start">
        <label for="OrderNumber" class="form-label">{{
          language('Order Number')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="number"
          class="form-control"
          v-model="form.orderNum"
          id="OrderNumber"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
        />
        <em class="red text-xs" v-if="isSubmit && errors.orderNum">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
  </form>
</template>
