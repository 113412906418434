<script setup>
import { ref, onMounted, inject, computed } from 'vue';
import { useRouter } from 'vue-router';
import * as _ from 'lodash';
import { useMainStore } from '@/store/pinia';

import { getStrength, updateStrength } from '@/network/apis/strength';
import { access } from '@/ultis';

const l = inject('L');
const router = useRouter();
let strength = ref();
let inputKeyword = ref();
const showEdit = ref(false);
let saved = ref(false);
const store = useMainStore();
const accessCode  = computed(() => parseInt(store.auth?.user?.sub_code.value));
const timer = ref(null);

const language = (text) => {
  return l[_.camelCase(text)];
};
const fetchStrength = async () => {
  const res = await getStrength(router.currentRoute._value.params.id);
  if (!res) return;
  if (res.advantage) {
    strength.value = res.advantage || {};
    inputKeyword.value = res.advantage.advDesc;
  }
};

const handEdit = () => {
  showEdit.value = true;
}

const handleInput = () => {
  if (timer.value) {
    clearTimeout(timer.value);
  }
}

const saveStrengthDebounced = _.debounce(async () => {
  saved.value = false;
  handleInput();
  await updateStrength(router.currentRoute._value.params.id, {
    ...strength.value,
    empId: router.currentRoute._value.params.id,
    advDesc: inputKeyword.value,
  });

  setTimeout(() => {
    saved.value = true;
  })
  setTimeout(() => {
    saved.value = false;
  }, 2000)
  timer.value = setTimeout(() => {
    showEdit.value = 0;
  }, 3000)
}, 1000);

onMounted(() =>
  setTimeout(() => {
    fetchStrength();
  }, 1300)
);

</script>
<template>
  <div class="row pt-4">
    <div class="col-xxl-12">
      <div class="textarea-box">
        <i v-if="saved" class="fas saved fa-check-circle"></i>
        <textarea
          v-if="showEdit"
          :placeholder="language('Placeholder Input Strength')"
          class="form-control"
          rows="10"
          v-model="inputKeyword"
          @input="saveStrengthDebounced"
          :disabled="!access(accessCode, ['MANAGER', 'ADMIN'])"
        />
        <div class="pre-text p-4" v-if="!showEdit" :class="access(accessCode, ['MANAGER', 'ADMIN']) ? 'box-content-text-area' : ''">
          {{ inputKeyword }}
          <i class="far fa-edit" v-if="access(accessCode, ['MANAGER', 'ADMIN'])" @click="handEdit"></i>
        </div>
      </div>
    </div>
  </div>
</template>
