<script setup>
import { ref, onMounted, inject, computed, watch } from 'vue';
import {
  listExp,
  deteleExp,
  createExp,
  editExp,
} from '@/network/apis/experiences';
import { useRoute } from 'vue-router';
import * as _ from 'lodash';
import { useMainStore } from '@/store/pinia';

import { date } from '@/ultis';
import InfoItem from '../../InfoItem';
import PopupAddEdit from '@/components/Actions/PopupAddEdit';
import Loading from '@/components/Loading';
import NoData from '@/components/NoData';
import { access } from '@/ultis';

const store = useMainStore();
const accessCode = computed(() =>
  parseInt(store.auth?.user?.sub_code.value)
);
const l = inject('L');
const route = useRoute();
const excId = route.params.id;
const careers = ref([]);
const params = ref({
  limit: 100,
  page: 1,
  empId: excId,
});
const totalExct = ref(0);
const loading = ref(true);
const currentlyWorking = {
  Y: 'Y',
  N: 'N',
};

const language = (text) => {
  return l.executiveInfo.forms.experience[_.camelCase(text)];
};
const fetchData = async () => {
  loading.value = true;

  const res = await listExp(params.value);
  if (!res) return;

  careers.value = res.careers;
  totalExct.value = res.itemCount;

  setTimeout(() => {
    loading.value = false;
  }, 1000);
};

onMounted(() => fetchData());

const data = computed(() => {
  return _.reduce(
    careers.value,
    (list, item) => {
      const original = {
        crSdt: item.startWorkingDate,
        crEnterYn: item.crEnterYn,
        crEdt: item.endWorkingDate,
        crAppointDt: item.establishedDate,
        crNm: item.company,
        crItem: item.workingField,
        crEmpType: item.employmentType,
        crJob: item.jobTitle,
        crDesignation: item.responsibility,
        crRank: item.jobLevel,
        crDept: item.department,
      };

      list.push([
        {
          label: language('Start working date'),
          value: date(item.startWorkingDate),
          refId: item.crId,
          original,
        },
        {
          label: language('End working date'),
          value:
            item.crEnterYn !== currentlyWorking.Y
              ? date(item.endWorkingDate)
              : language('currentlyWorking'),
          refId: item.crId,
          original,
        },
        {
          label: language('Established date'),
          value: date(item.establishedDate),
          refId: item.crId,
          original,
        },
        {
          label: language('Company'),
          value: item.company,
          refId: item.crId,
          original,
        },
        {
          label: language('Working Field'),
          value: item.workingField,
          refId: item.crId,
          original,
        },
        {
          label: language('Job title'),
          value: item.jobTitle,
          refId: item.crId,
          original,
        },
        {
          label: language('Responsibility'),
          value: item.responsibility,
          refId: item.crId,
          original,
        },
        {
          label: language('Job Level'),
          value: item.jobLevel,
          refId: item.crId,
          original,
        },
        {
          label: language('Employment Type'),
          value: item.employmentType,
          refId: item.crId,
          original,
        },
        {
          label: language('Department'),
          value: item.department,
          refId: item.crId,
          original,
        },
      ]);
      return list;
    },
    []
  );
});

const delFn = async (id) => {
  await deteleExp(id);
  fetchData();

  return true;
};

const addEditFn = async (params, id) => {
  let res = null;

  if (id) {
    res = await editExp(id, params)
  } else {
    res = await createExp(params)
  }

  if (!res) return false;

  return true;
};

const addCertFn = async (params) => {
  const res = await createExp(params);

  if (!res) return false;

  return true;
};

watch(careers, () => {
  data.value;
});
</script>
<template>
  <div class="row mt-4">
    <div v-if="!loading" class="d-flex justify-content-end mb-2">
      <PopupAddEdit v-if="access(accessCode, ['MANAGER', 'ADMIN'])" :parent-id="excId" :add-edit-fn="addCertFn" title="Add" size="md" type="EXPE" :is-add="true" :options="{ refreshData: fetchData }" />
    </div>
    <NoData v-if="!loading && _.size(careers) === 0" />
    <Loading v-if="loading" />
    <div v-if="!loading">
      <InfoItem
        v-for="(item, key) in data"
        :key="key"
        :data="item"
        :add-edit-fn="addEditFn"
        :del-fn="delFn"
        type="EXPE"
        :emp-id="excId"
        :options="{ refreshData: fetchData }"
      />
    </div>
  </div>
</template>
