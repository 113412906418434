<script setup>
import { inject, onMounted, onUnmounted, ref, watch } from 'vue';
import Button from '@/components/Button.vue';
import Select from '@/components/Select.vue';
import * as _ from 'lodash';
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import { getExecutiveOptions } from '@/network/apis/subCode';
import avatar from '@/assets/img/avatar.jpg';
import { DEFAULT_DATE_FORMAT } from '@/config';
import DatePicker from '@/components/DatePicker.vue';
import { vMaska } from "maska/vue"
import CropAvatar from '@/components/Cropper.vue';

const l = inject('L');

const language = (text) => {
  return _.get(l, text, text);
};

/**
 * Define props companyId
 * */
const { companyId, formValues } = defineProps(['companyId', 'companyOptions', 'formValues']);

const { defineField, errors, handleSubmit, setFieldValue, isSubmitting, values, setValues, validateField } = useForm({
  initialValues:  {
    comId: companyId,
  },
  validationSchema: yup.object().shape({
    comId: yup.string().required(language('thisIsARequiredField')),
    empNm: yup.string().required(language('thisIsARequiredField')),
    email: yup.string().email(language('invalidEmail')).nullable(),
    empId: yup.string().required(language('thisIsARequiredField')),
    // 11 char + 2 '-'
    phoneNum: yup.string().test('phoneNum', language('invalidPhoneNumber'), value => {
      if (!value) return true;

      return value.match(/^\d{3}-\d{4}-\d{4}$/);
    }).nullable(),
    postNum: yup.string().test('postNum', language('postCodeValid'), value => {
      if (!value) return true;

      return (/^\d{5}$/).test(value);
    }).nullable()
  })
});

/**
 * Define form field
 * */
const [comId, comId_attrs] = defineField('comId');
const [empNm, empNm_attrs] = defineField('empNm');
const [empDept, empDept_attrs] = defineField('empDept');
const [empNum, empNum_attrs] = defineField('empNum');
const [birthDt, birthDt_attrs] = defineField('birthDt');
const [phoneNum, phoneNum_attrs] = defineField('phoneNum');
const [email, email_attrs] = defineField('email');
const [addr, addr_attrs] = defineField('addr');
const [addrDetail, addrDetail_attrs] = defineField('addrDetail');
const [postNum, postNum_attrs] = defineField('postNum');
const [marriageYn, marriageYn_attrs] = defineField('marriageYn');
const [empId, empId_attrs] = defineField('empId');
const [designation, designation_attrs] = defineField('designation');
const [position, position_attrs] = defineField('position');
const [rank, rank_attrs] = defineField('rank');
const [marriageDt, marriageDt_attrs] = defineField('marriageDt');

/**
 * Define options
 * */
const jobTitleOptions = ref([]);
const jobLevelOptions = ref([]);
const responsibilityOptions = ref([]);
const url = ref(avatar);
const fileObj = ref();
const show = ref(false);
const close = ref(null);

function handleUploaded(obj) {
  fileObj.value = obj;
  close.value.click();
}

const showModalContent = (value) => {
  show.value = value;
}

const handleInput = async (field) => {
  await validateField(field);
};

/**
 * Define emit
 * */
const emit = defineEmits(['onSubmit', 'onBack']);

const onSubmit = handleSubmit(async () => {
  emit('onSubmit', values);
});
const onBack = () => {
  emit('onBack');
};

/**
 * Fetch options
 * */
onMounted(async () => {
  if (formValues) {
    const { media, ...rest } = formValues;

    setValues(rest, false);
    url.value = media;
  }

  const options = await getExecutiveOptions();

  jobLevelOptions.value = options.jobLevel.map(item => {
    return {
      value: item.subCode,
      text: item.name
    };
  });

  jobTitleOptions.value = options.jobTitle.map(item => {
    return {
      value: item.subCode,
      text: item.name
    };
  });

  responsibilityOptions.value = options.reponsibility.map(item => {
    return {
      value: item.subCode,
      text: item.name
    };
  });
});


onUnmounted(() => {
  URL.revokeObjectURL(url.value);
});

watch(marriageYn, (value) => {
  if (value === 'N' || !value) {
    setFieldValue('marriageDt', null);
  }
});

watch(fileObj, (value) => {
  if (value) {
    setFieldValue('media', value);
  }
});

</script>

<template>
  <div class="card mx-4">
    <div class="card-body">
      <form @submit.prevent="onSubmit">
        <div class="row">
          <div class="col-sm-12 col-md-6 order-md-1 order-2">
            <Select :label="language('executiveInfo.general.company') + ' *'" placeholder="Company" v-model="comId" :items="companyOptions" v-bind="comId_attrs" :error="errors.comId" />

            <p class="text-uppercase text-lg font-weight-bold border-bottom">{{ language('executiveInfo.personalInfo') }}</p>
            <div class="row">
              <div class="col-sm">
                <div class="form-group">
                  <label for="empNm">{{ language('executiveInfo.general.name') }} *</label>
                  <input v-model="empNm" v-bind="empNm_attrs" maxlength="30" :placeholder="language('executiveInfo.general.name')" :class="{
                    'form-control': true,
                    'is-invalid': errors.empNm
                  }">
                  <div class="invalid-feedback" v-if="errors.empNm">
                    {{ errors.empNm }}
                  </div>
                </div>
              </div>

              <div class="col-sm">
                <div class="form-group">
                  <label for="birthDt">{{ language('executiveInfo.general.dateOfBirth') }}</label>
                  <DatePicker :placeholder="DEFAULT_DATE_FORMAT" v-model="birthDt" v-bind="birthDt_attrs" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <div class="form-group">
                  <label for="phoneNum">{{ language('executiveInfo.general.phoneNumber') }}</label>
                  <input v-maska="'###-####-####'" v-model="phoneNum" v-bind="phoneNum_attrs" class="form-control" id="phoneNum" :placeholder="language('executiveInfo.general.phoneNumber')" :class="{
                    'form-control': true,
                    'is-invalid': errors.phoneNum
                  }"/>
                  <div class="invalid-feedback" v-if="errors.phoneNum">
                    {{ errors.phoneNum }}
                  </div>
                </div>
              </div>

              <div class="col-sm">
                <div class="form-group">
                  <label for="email">{{ language('executiveInfo.general.email') }}</label>
                  <input v-model="email" v-bind="email_attrs" maxlength="100" type="email" class="form-control" @keyup="handleInput('email')" id="email" :placeholder="language('executiveInfo.general.email')" :class="{
                    'form-control': true,
                    'is-invalid': errors.email
                  }" />
                  <div class="invalid-feedback" v-if="errors.email">
                    {{ errors.email }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <div class="form-group">
                  <label for="postNum">{{ language('executiveInfo.general.postalCode') }}</label>
                  <input v-model="postNum" v-bind="postNum_attrs" maxlength="5" type="text" class="form-control" id="postNum" :placeholder="language('executiveInfo.general.postalCode')" :class="{
                    'form-control': true,
                    'is-invalid': errors.postNum
                  }" />
                  <div class="invalid-feedback" v-if="errors.postNum">
                    {{ errors.postNum }}
                  </div>
                </div>
              </div>

              <div class="col-sm">
                <div class="form-group">
                  <label for="addr">{{ language('executiveInfo.general.homeAddress') }} 1</label>
                  <input v-model="addr" v-bind="addr_attrs" maxlength="100" type="text" class="form-control" id="addr" :placeholder="language('executiveInfo.general.homeAddress') + '1'">
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="addrDetail">{{ language('executiveInfo.general.homeAddress') }} 2</label>
              <input v-model="addrDetail" v-bind="addrDetail_attrs" type="text" maxlength="100" class="form-control" id="addrDetail" :placeholder="language('executiveInfo.general.homeAddress') + '2'">
            </div>

            <div class="row">
              <div class="col-sm">
                <div class="form-group">
                  <label for="marriageYn">{{ language('executiveInfo.general.maritalStatus') }}</label>
                  <div class="form-check">
                    <input type="radio" class="form-check-input" name="marriageYn" id="No" value="N" v-model="marriageYn" v-bind="marriageYn_attrs" />
                    <label for="No" class="custom-control-label">{{ language('no') }}</label>
                  </div>
                  <div class="form-check">
                    <input type="radio" class="form-check-input" name="marriageYn" id="Yes" value="Y" v-model="marriageYn" v-bind="marriageYn_attrs" />
                    <label for="Yes" class="custom-control-label">{{ language('yes') }}</label>
                  </div>
                </div>
              </div>
              <div class="col-sm">
                <div class="form-group">
                  <label for="marriageDt">{{ language('executiveInfo.general.weddingDate')}}</label>
                  <DatePicker :placeholder="DEFAULT_DATE_FORMAT" v-model="marriageDt" v-bind="marriageDt_attrs" :disabled="marriageYn === 'N' || !marriageYn" />
                </div>
              </div>
            </div>
            <p class="text-uppercase text-lg font-weight-bold border-bottom">{{ language('executiveInfo.jobInfo') }}</p>
            <div class="row">
              <div class="col-sm">
                <div class="form-group">
                  <label for="empId">{{ language('executiveInfo.general.employeeId') }} *</label>
                  <input :disabled="!!formValues" maxlength="30" v-model="empId" v-bind="empId_attrs" type="text" class="form-control" id="empId" :placeholder="language('executiveInfo.general.employeeId')" :class="{
                    'form-control': true,
                    'is-invalid': errors.empId
                  }">
                  <div class="invalid-feedback" v-if="errors.empId">
                    {{ errors.empId }}
                  </div>
                </div>
              </div>

              <div class="col-sm">
                <div class="form-group">
                  <label for="empNum">{{ language('executiveInfo.general.employeeNumber') }}</label>
                  <input v-model="empNum" maxlength="30" v-bind="empNum_attrs" type="text" class="form-control" id="empNum" :placeholder="language('executiveInfo.general.employeeNumber')">
                </div>
              </div>
              <div class="col-sm">
                <div class="form-group">
                  <label for="empDept">{{ language('executiveInfo.general.department')}}</label>
                  <input v-model="empDept" maxlength="30" v-bind="empDept_attrs" type="text" class="form-control" id="empDept" :placeholder="language('executiveInfo.general.department')" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <Select :label="language('executiveInfo.general.jobTitle')" placeholder="Job title" v-model="position" :show-empty="true" :items="jobTitleOptions" v-bind="position_attrs" />
              </div>
              <div class="col-sm">
                <Select :label="language('executiveInfo.general.jobLevel')" placeholder="Job level" v-model="rank" :show-empty="true" :items="jobLevelOptions" v-bind="rank_attrs" />
              </div>
              <div class="col-sm">
                <Select :label="language('executiveInfo.general.responsibility')" :placeholder="language('executiveInfo.general.responsibility')" :show-empty="true" v-model="designation" :items="responsibilityOptions" v-bind="designation_attrs" />
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 order-sm-1 order-md-2 order-1">
            <div class="text-center mt-4">
              <div class="box-avatar mb-4">
                <span class="avatar-preview">
                  <img v-if="fileObj" :src="fileObj?.filePath" alt="Avatar" />
                  <img v-if="!fileObj && !formValues?.media" src="../../assets/img/avatar.jpg" alt="Avatar" />
                  <img v-if="!fileObj && formValues?.media" :src="formValues?.media" alt="Avatar" />
                </span>
              </div>
              <button class="btn btn-sm" @click="showModalContent(true)" type="button" data-bs-toggle="modal" data-bs-target="#modal-upload-avatar">
                <i class="fas fa-sync-alt"></i> {{ language('changeAvatar') }}
              </button>
              <div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="modal-upload-avatar" tabindex="-1" aria-labelledby="editModelLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">{{ language('upload') }}</h5>
                      <button type="button" class="btn-close btn-sm" @click="showModalContent(false)" ref="close" data-bs-dismiss="modal" aria-label="Close"><i class="far fa-times"></i></button>
                    </div>
                    <div class="modal-body" v-if="show">
                      <CropAvatar @uploaded="handleUploaded" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Button class="me-2" type="button" variant="outline" @click="onBack">{{ language('cancel') }}</Button>
        <Button type="submit" :loading="isSubmitting">{{ language('modal.save') }}</Button>
      </form>
    </div>
  </div>
</template>