<script setup>
import { inject, computed, defineEmits, ref } from 'vue';
import * as _ from 'lodash';

import DatePicker from '@/components/DatePicker';

const l = inject('L');
const emits = defineEmits(['submitForm']);

const props = defineProps({
  data: {
    comNm: String,
    comEngNm: String,
    comRegistNum: String,
    comLocation: String,
    comDt: String,
    comPostNum: String,
    comAddr: String,
    comAddrDetail: String,
    orderNum: Number,
  },
  options: Object,
})

const form = computed(() => ({ ...props.data }));

const isSubmit = ref(false);
const errors = ref({
  comNm: 'R',
});

const validateForm = async () => {
  const obj = form.value;

  for (const key of Object.keys(obj)) {
    if (errors.value[key]) {
      errors.value[key] = !obj[key] ? 'R' : 'N/A';
    }
  }
};

const postCodeIsValid = ref((props.data?.comPostNum && _.size(props.data?.comPostNum) != 5) ? false : true);

const checkPostCode = () => {
  if (!form.value.comPostNum) {
    postCodeIsValid.value = true;
  } else {
    postCodeIsValid.value = (/^\d{5}$/).test(form.value.comPostNum);
  }
}

const handleSubmit = async () => {
  isSubmit.value = true;
  await validateForm();
  
  const checkError = Object.values(errors.value).some(
    (value) => value === 'R'
  );

  if (checkError || !postCodeIsValid.value) {
    return;
  }

  const payload = (props.options && props.options.isAdd) ? form.value : _.omit(form.value, ['mstCode']);

  emits('submitForm', payload);
};

const language = (text, isCommon) => {
  if (isCommon) return l[_.camelCase(text)];

  return l.forms.companies[_.camelCase(text)];
};

const showMsgErr = (submited, key) => {
  return submited && key === 'R';
}

defineExpose({
  handleSubmit
});
</script>
<template>
  <form @submit.prevent="handleSubmit">
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4 text-start">
        <label for="CompanyName" class="form-label">{{
          language('Company Name')
        }}<em class="red">*</em></label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="form.comNm"
          id="CompanyName"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="30"
        />
        <em class="red text-xs" v-if="showMsgErr(isSubmit, errors.comNm)">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4 text-start">
        <label for="EnglishName" class="form-label">{{
          language('English Name')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="form.comEngNm"
          id="EnglishName"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="30"
        />
        <em class="red text-xs" v-if="showMsgErr(isSubmit, errors.comEngNm)">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4 text-start">
        <label for="BusinessCode" class="form-label">{{
          language('Business code')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="form.comRegistNum"
          id="BusinessCode"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="30"
        />
        <em class="red text-xs" v-if="isSubmit && errors.comRegistNum">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group mb-0">
      <div class="col-md-5 col-xl-5 col-xxl-4 text-start">
        <label for="Address" class="form-label">{{
          language('Address')
        }}</label>
      </div>
      <div class="col-md-2 col-xl-2 col-xxl-3 mb-3">
        <input
          type="text"
          class="form-control"
          v-model="form.comPostNum"
          id="Address"
          :placeholder="language('Enter your text', true)"
          @input="checkPostCode"
          maxlength="5"
        />
        <em class="red text-xs" v-if="isSubmit && !postCodeIsValid">{{ language('Post Code Valid', true) }}</em>
      </div>
      <div class="col-md-5 col-xl-5 col-xxl-5 mb-3">
        <input
          type="text"
          class="form-control"
          v-model="form.comAddr"
          id="Address"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="100"
        />
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="form.comAddrDetail"
          id="Address"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="100"
        />
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4 text-start">
        <label for="Workplace" class="form-label">{{
          language('Workplace')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="form.comLocation"
          id="Workplace"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="50"
        />
        <em class="red text-xs" v-if="isSubmit && errors.comLocation">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4 text-start">
        <label for="EstablishedDate " class="form-label">{{
          language('Established date ')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <DatePicker v-model="form.comDt" @change="validateForm" />
        <em class="red text-xs" v-if="isSubmit && errors.comDt">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4 text-start">
        <label for="OrderNumber" class="form-label">{{
          language('Sort Number ')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="number"
          class="form-control"
          v-model="form.orderNum"
          id="OrderNumber"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
        />
        <em class="red text-xs" v-if="isSubmit && errors.orderNum">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
  </form>
</template>
