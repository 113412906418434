import { callApi } from '../index';

export const listExp = async (params) => {
  return await callApi('get', `/career`,params);
};
export const deteleExp = async (id) => {
  return await callApi('delete', `/career/${id}`);
};

export const createExp = async (params) => {
  return await callApi('post', `/career`, params);
};

export const editExp = async (id, params) => {
  return await callApi('put', `/career/${id}`, params);
};
