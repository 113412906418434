<script setup>
import { defineProps, inject } from 'vue';
import * as _ from 'lodash';
import { date } from '@/ultis';
import avatar from '@/assets/img/avatar.jpg';

const l = inject('L');
const language = (text) => {
  return l.executiveInfo.general[_.camelCase(text)];
};

defineProps({
  user: Object,
});

const wedding = (value) =>
  value && value.marriageDate
    ? date(value.marriageDate)
    : '';
const birdday = (value) =>
  value && value ? date(value) : 'YYYY-MM-DD';
</script>
<template>
  <div class="row info-box">
    <div class="col-md-2 text-center mb-4">
      <span class="avatar-profile">
        <img width="120" :src="user?.media || avatar" alt="avatar" style="object-fit: cover"/>
      </span>
    </div>
    <div class="col-md-5">
      <div class="row mb-1">
        <div class="col-5 col-md-5 col-lg-2">{{ language('Name') }}</div>
        <div class="col-7 col-md-7 col-lg-8 text-bold text-bold">
          {{ user?.name }}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-5 col-md-5 col-lg-2">{{ language('Age') }}</div>
        <div class="col-7 col-md-7 col-lg-8 text-bold">
          {{ birdday(user?.birthday) }}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-5 col-md-5 col-lg-2">{{ language('Phone number') }}</div>
        <div class="col-7 col-md-7 col-lg-8 text-bold">{{ user?.phone }}</div>
      </div>
      <div class="row mb-1">
        <div class="col-5 col-md-5 col-lg-2">{{ language('Email') }}</div>
        <div class="col-7 col-md-7 col-lg-8 text-bold">{{ user?.email }}</div>
      </div>
      <div class="row mb-1">
        <div class="col-5 col-md-5 col-lg-2">{{ language('Department') }}</div>
        <div class="col-7 col-md-7 col-lg-8 text-bold">{{ user?.department }}</div>
      </div>
    </div>
    <div class="col-md-5">
      <div class="row mb-1">
        <div class="col-5 col-md-5 col-lg-2">{{ language('Company') }}</div>
        <div class="col-7 col-md-7 col-lg-8 text-bold">{{ user?.companyName }}</div>
      </div>
      <div class="row mb-1">
        <div class="col-5 col-md-5 col-lg-2">{{ language('Employee ID') }}</div>
        <div class="col-7 col-md-7 col-lg-8 text-bold">{{ user?.empId }}</div>
      </div>
      <div class="row mb-1">
        <div class="col-5 col-md-5 col-lg-2">{{ language('Employee Number') }}</div>
        <div class="col-7 col-md-7 col-lg-8 text-bold">{{ user?.empNumber }}</div>
      </div>
      <div class="row mb-1">
        <div class="col-5 col-md-5 col-lg-2">{{ language('Job Title') }}</div>
        <div class="col-7 col-md-7 col-lg-8 text-bold">{{ user?.jobTitle }}</div>
      </div>
      <div class="row mb-1">
        <div class="col-5 col-md-5 col-lg-2">{{ language('Responsibility') }}</div>
        <div class="col-7 col-md-7 col-lg-8 text-bold">
          {{ user?.responsibility }}
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-5 col-md-5 col-lg-2">{{ language('Job Level') }}</div>
        <div class="col-7 col-md-7 col-lg-8 text-bold">{{ user?.jobLevel }}</div>
      </div>
    </div>
  </div>
  <hr class="mt-4" />
  <div class="row info-box">
    <div class="col-md-6">
      <div class="row">
        <div class="col-5 col-md-5 col-lg-2">{{ language('Home Address') }}</div>
        <div class="col-7 col-md-7 col-lg-8 text-bold text-bold">
          {{
            `${user?.postNum ? `(${user?.postNum})` : ''} ${user?.address ? user?.address : ''} ${user?.addressDetail ? user?.addressDetail : ''}`
          }}
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-5 col-md-5 col-lg-2">{{ language('Marital Status') }}</div>
        <div class="col-7 col-md-7 col-lg-8 text-bold">
          {{ wedding(user) ? language('Marriage Yes') : language('Marriage No') }}
        </div>
      </div>
      <div class="row" v-if="wedding(user) ? true : false">
        <div class="col-5 col-md-5 col-lg-2">{{ language('Wedding Date') }}</div>
        <div class="col-7 col-md-7 col-lg-8 text-bold">{{ wedding(user) }}</div>
      </div>
    </div>
  </div>
</template>
