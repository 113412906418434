<script setup>
import { computed, onMounted, onBeforeUnmount  } from "vue";
import { useMainStore } from './store/pinia';
import SideBar from "@/components/SideBar";
import Navbar from "@/components/Navbar.vue";
import GoToTop from "@/components/GoToTop.vue";

const store = useMainStore();
const isShow  = computed(() => store.layout !== 'none');
const showNav  = computed(() => store.showNav);

const handleClick = async (event) => {
  if (!event.target.closest('.show-nav-mobile') && !event.target.closest('.nav-icon')) {
    await store.setNav(false);
  }
};

onMounted(() => {
  document.addEventListener('click', handleClick);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClick);
});

</script>
<template>
  <SideBar v-if="isShow" />

  <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
    <navbar v-if="isShow" />
    <div class="overlay" v-if="showNav"></div>
    <router-view />
    <app-footer v-if="isShow" />
    <GoToTop />
  </main>
</template>
