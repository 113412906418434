import { defineStore } from 'pinia';
import { ref } from 'vue';
import { setCookie, getCookie, removeCookie } from '@/ultis';

export const useMainStore = defineStore('store', () => {
  const auth = ref(getCookie('auth') || {
    user: null,
    token: null,
  });
  const layout = ref(getCookie('layout') || 'default');
  const showNav = ref(getCookie('showNav') || false);

  const setAuth = (data) => {
    auth.value = data;
    setCookie('auth', data, 30);
  }

  const setLayout = (data) => {
    layout.value = data;
    setCookie('layout', data, 30);
  }

  const setNav = (data) => {
    showNav.value = data;
    setCookie('showNav', data, 30);
  };

  const removeAuth = () => {
    showNav.value = {
      user: null,
      token: null,
    }

    removeCookie('auth');
  }

  return { auth, layout, showNav, setAuth, setLayout, setNav, removeAuth }
});
