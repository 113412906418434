<script setup>
import { inject, onMounted, ref, watch } from 'vue';
import * as _ from 'lodash';
import { useRoute } from 'vue-router';

import { getEducations, deleteEducation, editEducation, createEducation } from '@/network/apis/executives';
import InfoItem from '../../InfoItem';
import Loading from '@/components/Loading';
import NoData from '@/components/NoData';
import { date } from '@/ultis';

const props = defineProps({
  refresh: Boolean,
});

const route = useRoute();
const excId = route.params.id;
const loading = ref(true);

const l = inject('L');
const language = (text) => {
  return l.executiveInfo.forms.education[_.camelCase(text)];
};

const educations = ref([]);

const fetchData = async () => {
  loading.value = true;

  const res = await getEducations(excId, {
    empId: excId,
  });

  if (!res) return;

  educations.value = convertData(res.educations);
  setTimeout(() => {
    loading.value = false;
  }, 1000);
};

onMounted(() => fetchData());

const convertData = (arr) => {
  return _.reduce(
    arr,
    (list, item) => {
      const original = {
        eduEtype: item.degree,
        eduNm: item.intitutionName,
        eduMajor: item.major,
        eduMajorSub: item.secondMajor,
        eduSdt: item.admissonDate,
        eduEdt: item.graduationDate,
      };

      list.push([
        {
          label: language('Degree'),
          value: item.degree,
          refId: item.eduId,
          original,
        },
        {
          label: language('Institution name'),
          value: item.intitutionName,
          refId: item.eduId,
          original,
        },
        {
          label: language('Major​'),
          value: item.major,
          refId: item.eduId,
          original,
        },
        {
          label: language('Second major​'),
          value: item.secondMajor,
          refId: item.eduId,
          original,
        },
        {
          label: language('Admission date​'),
          value: item.admissonDate
            ? date(item.admissonDate)
            : '',
          refId: item.eduId,
          original,
        },
        {
          label: language('Graduation date​'),
          value: item.graduationDate
            ? date(item.graduationDate)
            : '',
          refId: item.eduId,
          original,
        },
      ]);

      return list;
    },
    []
  );
};

const delFn = async (id) => {
  await deleteEducation(id);
  fetchData();

  return true;
};

const addEditFn = async (params, id) => {
  let res = null;

  if (id) {
    res = await editEducation(id, params)
  } else {
    res = await createEducation(params)
  }

  if (!res) return false;

  return true;
};

watch(
  () => props.refresh,
  () => fetchData(),
  { deep: true }
);
</script>
<template>
  <NoData v-if="!loading && _.size(educations) === 0" />
  <Loading v-if="loading" />
  <div v-if="!loading">
    <InfoItem
      v-for="(item, key) in educations"
      :key="key"
      :data="item"
      :add-edit-fn="addEditFn"
      :del-fn="delFn"
      type="EDUCATION"
      :emp-id="excId"
      :options="{ refreshData: fetchData }"
    />
  </div>
</template>
