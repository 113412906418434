<script setup>
import { ref, onMounted, inject, computed, watch } from 'vue';
import { listCert, deteleCert, createCert, editCert } from '@/network/apis/certifications';
import { useRoute } from 'vue-router';
import * as _ from 'lodash';
import { useMainStore } from '@/store/pinia';

import { date } from '@/ultis';
import InfoItem from '../../InfoItem';
import Loading from '@/components/Loading';
import NoData from '@/components/NoData';
import PopupAddEdit from '@/components/Actions/PopupAddEdit';
import { access } from '@/ultis';

const l = inject('L');
const route = useRoute();
const excId = route.params.id
const store = useMainStore();
const accessCode  = computed(() => parseInt(store.auth?.user?.sub_code.value));

const liscenses = ref([]);
const params = ref({
  limit: 100,
  page: 1,
  empId: excId,
});
const totalExct = ref(0);
const loading = ref(true);

const language = (text) => {
  return l.executiveInfo.forms.certification[_.camelCase(text)];
};
const fetchData = async () => {
  loading.value = true;
  const res = await listCert(params.value);
  if (!res) return;

  liscenses.value = res.liscenses;
  totalExct.value = res.itemCount;

  setTimeout(() => {
    loading.value = false;
  }, 1000);
};

onMounted(() => fetchData());

const data = computed(() => {
  return _.reduce(
    liscenses.value,
    (list, item) => {
      const original = {
        licNm: item.certification,
        licGrade: item.level,
        licOrg: item.publisher,
        licDt: item.acquisitionDate
      };

      list.push([
        {
          label: language('Certification'),
          value: item.certification,
          refId: item.licId,
          original,
        },
        {
          label: language('Certification level'),
          value: item.level,
          refId: item.licId,
          original,
        },
        {
          label: language('Publisher'),
          value: item.publisher,
          refId: item.licId,
          original,
        },
        {
          label: language('Acquisition Date'),
          value: date(item.acquisitionDate),
          refId: item.licId,
          original,
        },
      ]);

      return list;
    },
    []
  );
});

const delFn = async (id) => {
  await deteleCert(id);
  fetchData();

  return true;
};

const addEditFn = async (params, id) => {
  let res = null;

  if (id) {
    res = await editCert(id, params)
  } else {
    res = await createCert(params)
  }

  if (!res) return false;

  return true;
};

const addCertFn = async (params) => {
  const res = await createCert(params);

  if (!res) return false;

  return true;
};

watch(liscenses, () => {
  data.value;
});

</script>
<template>
  <div class="row mt-4">
    <div v-if="!loading" class="d-flex justify-content-end mb-2">
      <PopupAddEdit v-if="access(accessCode, ['MANAGER', 'ADMIN'])" :parent-id="excId" :add-edit-fn="addCertFn" title="Add" size="md" type="CERT" :is-add="true" :options="{ refreshData: fetchData }" />
    </div>
    <NoData v-if="!loading && _.size(liscenses) === 0" />
    <Loading v-if="loading" />
    <div v-if="!loading">
      <InfoItem
        v-for="(item, key) in data"
        :key="key"
        :data="item"
        :add-edit-fn="addEditFn"
        :del-fn="delFn"
        type="CERT"
        :emp-id="excId"
        :options="{ refreshData: fetchData }"
      />
    </div>
  </div>
</template>
