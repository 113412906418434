import { callApi } from '../index';

export const listSubMaster = async (params) => {
  return await callApi('get', '/sub-code', params);
};
export const deleteSubMaster = async (id) => {
  return await callApi('delete', `/sub-code/${id}`);
};

export const createSubMaster = async (params) => {
  return await callApi('post', `/sub-code`, params);
};

export const editSubMaster = async (id, params) => {
  return await callApi('put', `/sub-code/${id}`, params);
};
