import { callApi } from '../index';

export const overview = async () => {
  return await callApi('get', '/company/overview/chart');
};

export const getCompanies = async (params) => {
  return await callApi('get', '/company', params);
};
export const deleteCompany = async (id) => {
  return await callApi('delete', `/company/${id}`);
};

export const createCompany = async (params) => {
  return await callApi('post', `/company`, params);
};

export const editCompany = async (id, params) => {
  return await callApi('put', `/company/${id}`, params);
};
