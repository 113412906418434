<script setup>
import { inject, computed, defineEmits, ref } from 'vue';
import * as _ from 'lodash';
import DatePicker from '@/components/DatePicker';
import { compareDate } from '@/ultis';

const l = inject('L');
const emits = defineEmits(['submitForm']);

const props = defineProps({
  data: {
    netNm: String,
    netPosition: String,
    netRelation: String,
    netCompany: String,
    netWorkSdt: String,
    netWorkEdt: String,
    netProfile: String,
  },
})

const form = computed(() => ({ ...props.data }));

const isSubmit = ref(false);
const errors = ref({
  sdtBeforeEdt: false,
});

const validateForm = () => {
  const obj = form.value;

  for (const key of Object.keys(obj)) {
    if (key === 'netWorkEdt') {
      errors.value.sdtBeforeEdt = compareDate(form.value.netWorkEdt, form.value.netWorkSdt);
    }
  }
};

const handleSubmit = async () => {
  isSubmit.value = true;
  validateForm();
  
  const checkError = Object.values(errors.value).some(
    (value) => value === true
  );

  if (checkError) {
    return;
  }

  emits('submitForm', form.value);
};

const language = (text, isCommon) => {
  if (isCommon) return l[_.camelCase(text)];

  return l.executiveInfo.forms.network[_.camelCase(text)];
};

defineExpose({
  handleSubmit
});
</script>
<template>
  <form @submit.prevent="handleSubmit">
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="Name" class="form-label">{{
          language('Name')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="form.netNm"
          id="Name"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="50"
        />
        <em class="red text-xs" v-if="isSubmit && errors.netNm">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="JobTitle" class="form-label">{{
          language('Job Title')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="form.netPosition"
          id="JobTitle"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="50"
        />
        <em class="red text-xs" v-if="isSubmit && errors.netPosition">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="Relationship" class="form-label">{{
          language('Relationship')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="form.netRelation"
          id="Relationship"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="20"
        />
        <em class="red text-xs" v-if="isSubmit && errors.netRelation">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="Affiliation" class="form-label">{{
          language('Affiliation')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="form.netCompany"
          id="Affiliation"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="100"
        />
        <em class="red text-xs" v-if="isSubmit && errors.netCompany">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="EmploymentPeriod" class="form-label">{{
          language('Employment period')
        }}</label>
      </div>
      <div class="col-md-3 col-xl-3 col-xxl-4">
        <DatePicker v-model="form.netWorkSdt" @change="validateForm" />
        <em class="red text-xs" v-if="isSubmit && errors.netWorkSdt">{{ language('This is a required field', true) }}</em>
      </div>
      <div class="col-md-1 col-xl-1 d-xxl-none mobile-show"></div>
      <div class="col-md-3 col-xl-3 col-xxl-4">
        <DatePicker v-model="form.netWorkEdt" @change="validateForm" />
        <em class="red text-xs" v-if="isSubmit && errors.netWorkEdt">{{ language('This is a required field', true) }}</em>
      </div>
      <div class="col-md-5 col-xl-5 col-xxl-5"></div>
      <div class="col-md-1 col-xl-1 col-xxl-1"></div>
      <div class="col-md-7 col-xl-7 col-xxl-7">
        <em class="red text-xs" v-if="isSubmit && errors.sdtBeforeEdt">{{ language('To Date must be greater than From Date', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-12">
        <label for="MainBiography" class="form-label">{{
          language('Main Biography')
        }}</label>
      </div>
      <div class="col-md-12">
        <textarea
          class="form-control"
          v-model="form.netProfile"
          id="MainBiography"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          rows="5"
        ></textarea>
        <em class="red text-xs" v-if="isSubmit && errors.netProfile">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
  </form>
</template>
