<script setup>
import { onMounted, ref, watch, inject } from 'vue';
import * as _ from 'lodash';
import { useRoute } from 'vue-router';

import { getFamilies, deleteFamily, createFamily, editFamily } from '@/network/apis/executives';
import InfoItem from '../../InfoItem';
import Loading from '@/components/Loading';
import NoData from '@/components/NoData';
import { date } from '@/ultis';

const props = defineProps({
  refresh: Boolean,
});

const route = useRoute();
const excId = route.params.id;
const loading = ref(true);

const l = inject('L');
const language = (text) => {
  return l.executiveInfo.forms.family[_.camelCase(text)];
};

const families = ref([]);

const fetchData = async () => {
  loading.value = true;
  const res = await getFamilies(excId, {
    empId: excId,
  });
  if (!res) return;

  families.value = convertData(res.families);

  setTimeout(() => {
    loading.value = false;
  }, 1000);
};

onMounted(() => fetchData());

const convertData = (arr) => {
  return _.reduce(
    arr,
    (list, item) => {
      const original = {
        famRelation: item.relationshipType,
        famNm: item.name,
        famJob: item.job,
        famBirthDt: item.dateOfBirth
      };

      list.push([
        {
          label: language('Relationship type'),
          value: item.relationshipType,
          refId: item.famId,
          original,
        },
        {
          label: language('Name'),
          value: item.name,
          refId: item.famId,
          original
        },
        {
          label: language('Job'),
          value: item.job,
          refId: item.famId,
          original
        },
        {
          label: language('Date of birth'),
          value: item.dateOfBirth
            ? date(item.dateOfBirth)
            : '',
          refId: item.famId,
          original
        },
      ]);

      return list;
    },
    []
  );
};

const delFn = async (id) => {
  await deleteFamily(id);
  fetchData();

  return true;
};

const addEditFn = async (params, id) => {
  let res = null;

  if (id) {
    res = await editFamily(id, params)
  } else {
    res = await createFamily(params)
  }

  if (!res) return false;

  return true;
};

watch(
  () => props.refresh,
  () => fetchData(),
  { deep: true }
);
</script>
<template>
  <NoData v-if="!loading && _.size(families) === 0" />
  <Loading v-if="loading" />
  <div v-if="!loading">
    <InfoItem
      v-for="(item, key) in families"
      :key="key"
      :data="item"
      :add-edit-fn="addEditFn"
      :del-fn="delFn"
      type="FAMILY"
      :emp-id="excId"
      :options="{ refreshData: fetchData }"
    />
  </div>
</template>
