<script setup>
import GeneralInfoForm from '@/views/components/GeneralInfoForm.vue';
import { useRouter } from 'vue-router';
import { createExecutive, patchUpdateExecutive } from '@/network/apis/executives';
import { toast } from 'vue3-toastify';
import { inject, onMounted, ref } from 'vue';
import * as _ from 'lodash';
import { getCompanies } from '@/network/apis/companies';
import Loading from '@/components/Loading.vue';
import errors from '@/consts/errors';

const router = useRouter();
const companyId = router.currentRoute.value.query.companyId;

const l = inject('L');
const $swal = inject('$swal');

const language = (text, data) => {
  let txt = _.get(l, text, text);

  if (data) {
    Object.entries(data).forEach(([key, value]) => {
      txt = txt.replace(`{{ ${key} }}`, value);
    });

    return txt;
  }

  return txt;
};

const companyOptions = ref([]);

const onSubmit = async (values) => {
  const comNm = companyOptions.value.find(item => item.value === values.comId).text;

  const rest = await createExecutive({ ...values, comNm }, { shouldShowToast: false });

  /**
   * If the employee already exists, we will show a modal to confirm the overwrite
   * Instead of showing a toast, we will show a modal and use updateExecutives to update
   * */
  if (rest === errors.EMPLOYEE_ALREADY_EXIST) {
    $swal
        .fire({
          title: language('employeeNumberExist'),
          text: language('contentConfirmOverwrite', { empId: values.empId }),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#2dce89',
          cancelButtonColor: '#ccc',
          confirmButtonText: language('overwrite'),
          cancelButtonText: language('enterDifferentEmployeeNumber'),
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await patchUpdateExecutive(values.empId, { ...values, comNm });
            await router.push(`/executive/${values.empId}`);
            toast.success(language('executiveUpdated'));
          }
        });
    return;
  }

  // Any other error
  if (typeof rest === 'string') {
    toast.error(language(l.errors[rest] || rest));
    return;
  }

  toast.success(language('executiveAdded'));
  router.back();
};

const goBack = () => {
  router.back();
};

onMounted(async () => {
  const response  = await getCompanies({ limit: 100 });

  companyOptions.value = response.companies.map(item => {
    return {
      value: item.id,
      text: item.companyName
    }
  });
})

</script>

<template>
  <div v-if="!companyOptions.length" class="card min-vh-100 pb-4">
    <div class="card-body">
      <Loading />
    </div>
  </div>
  <GeneralInfoForm
      v-if="!!companyOptions.length"
      :company-options="companyOptions"
      :company-id="companyId"
      @on-submit="onSubmit"
      @on-back="goBack"
  />
</template>