<script setup>
import { inject, computed, defineEmits, ref } from 'vue';
import * as _ from 'lodash';
import DatePicker from '@/components/DatePicker';

const l = inject('L');
const emits = defineEmits(['submitForm']);

const props = defineProps({
  data: {
    licNm: String,
    licGrade: String,
    licOrg: String,
    licDt: String,
  },
})

const form = computed(() => ({ ...props.data }));

const isSubmit = ref(false);
const errors = ref({});

const validateForm = () => {
  const obj = form.value;

  for (const key of Object.keys(obj)) {
    if (errors.value[key]) {
      errors.value[key] = !obj[key];
    }
  }
};

const handleSubmit = async () => {
  isSubmit.value = true;
  validateForm();
  
  const checkError = Object.values(errors.value).some(
    (value) => value === true
  );

  if (checkError) {
    return;
  }

  emits('submitForm', form.value);
};

const language = (text, isCommon) => {
  if (isCommon) return l[_.camelCase(text)];

  return l.executiveInfo.forms.certification[_.camelCase(text)];
};

defineExpose({
  handleSubmit
});
</script>
<template>
  <form @submit.prevent="handleSubmit">
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="Certification" class="form-label">{{
          language('Certification')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="form.licNm"
          id="Certification"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="50"
        />
        <em class="red text-xs" v-if="isSubmit && errors.licNm">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="CertificationLevel" class="form-label">{{
          language('Certification Level')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="form.licGrade"
          id="CertificationLevel"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="50"
        />
        <em class="red text-xs" v-if="isSubmit && errors.licGrade">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="Publisher " class="form-label">{{
          language('Publisher ')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="form.licOrg"
          id="Publisher "
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="100"
        />
        <em class="red text-xs" v-if="isSubmit && errors.licOrg">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="AcquisitionDate" class="form-label">{{
          language('Acquisition Date')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <DatePicker v-model="form.licDt" @change="validateForm" />
        <em class="red text-xs" v-if="isSubmit && errors.licDt">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
  </form>
</template>
