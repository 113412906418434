import { callApi } from '../index';

export const listSalary = async (params) => {
  return await callApi('get', `/salary`,params);
};
export const deleteSalary = async (id) => {
  return await callApi('delete', `/salary/${id}`);
};

export const createSalary = async (params) => {
  return await callApi('post', `/salary`, params);
};

export const editSalary = async (id, params) => {
  return await callApi('put', `/salary/${id}`, params);
};
