<script setup>
import { ref, onMounted, computed, inject } from 'vue';
import * as _ from 'lodash';
import moment from 'moment';
import { useRoute } from 'vue-router';

import { yearOfApi, listKpi, createKpi, editKpi, deleteKpi } from '@/network/apis/kpi';
import Loading from '@/components/Loading';
import NoResult from '@/components/NoResult';
import { randomColor } from '@/ultis';
import PopupDelete from '@/components/Actions/PopupDelete';
import PopupAddEdit from '@/components/Actions/PopupAddEdit';
import Quarter from './Quarter';
import Final from './Final';

const size  = computed(() => window.innerWidth);
const l = inject('L');
const loading = ref(true);
const kpis = ref([]);
const route = useRoute();
const excId = route.params.id;
const params = ref({
  limit: 99,
  page: 1,
});
const year = ref(moment().year())
const range = ref([]);
const years = ref([]);
const collapseIdx = ref();
const isQuarterShow = ref(true);

const fetchData = async (sectionId, isQuarter) => {
  loading.value = true;

  const res = await listKpi({ ...params.value, empId: excId, year: year.value });
  if (!res) return;
  kpis.value = res.kpis;
  collapseIdx.value = typeof sectionId != 'undefined' ? sectionId : undefined;
  isQuarterShow.value = typeof isQuarter != 'undefined' ? isQuarter : true;

  setTimeout(() => {
    loading.value = false;
  }, 500);
};

const fetchYear = async () => {
  const res = await yearOfApi({ empId: excId });
  if (!res || _.size(res) === 0) {
    setTimeout(() => {
      loading.value = false;
    }, 500);

    return;
  };

  const list = _.sortBy(_.map(res, (r) => r.year));
  years.value = [ ...list ].sort();

  let current = 0;
  let idx = 0;

  for (const i in list) {
    if (Math.abs(list[i] - year.value) < Math.abs(current - year.value)) {
      current = list[i];
      idx = parseInt(i);
    }
  }

  const arr = [];
  
  if (idx > 0 && !list[idx + 1] && list[idx - 2]) {
    arr.push(list[idx - 2]);
  }

  if (idx > 0) {
    arr.push(list[idx - 1]);
  }

  arr.push(current);
  year.value = current;

  if (list[idx + 1]) {
    arr.push(list[idx + 1]);
  }

  if (idx === 0 && list[idx + 2]) {
    arr.push(list[idx + 2]);
  }

  range.value = [ ...arr ];

  setTimeout(() => fetchData());
};

const selectYear = (val) => {
  year.value = val;
  fetchData();
}

const page = (index) => {
  if (_.size(years.value) <= 3) return;
  const arr = _.cloneDeep(range.value);

  if (index === 'prev') {
    const yIdx = _.indexOf(years.value, arr[0]);
    if (yIdx <= 0) return;

    arr.pop();
    range.value = [ years.value[yIdx - 1], ...arr ];
  } else {
    const yIdx = _.indexOf(years.value, arr[2]);
    if (yIdx === _.size(years.value) - 1) return;

    arr.shift();
    range.value = [ ...arr, years.value[yIdx + 1] ];
  }
}

const addFnYear = async (params, id) => {
  let res = null;

  if (id) {
    res = await editKpi(id, params)
  } else {
    res = await createKpi(params)
  }

  if (!res) return false;

  return true;
};

const delFnYear = async (id) => {
  await deleteKpi(id);
  fetchYear();

  return true;
};

onMounted(() => fetchYear());

const isNoResult = computed(() => {
  return _.size(kpis.value) === 0 && !loading.value;
});

const language = (text) => {
  return l.executiveInfo.forms.evaluation[_.camelCase(text)];
};
</script>
<template>
  <div class="pt-4 evaluation-box">
    <div class="pb-4 d-flex justify-content-between evaluation-box-header">
      <i class="fas fa-angle-left" @click="page('prev')"></i>
      <div v-for="item in range" :key="item">
        <span :class="item === year ? 'active' : ''" @click="selectYear(item)">{{ item }}</span>
      </div>
      <i class="fas fa-angle-right" @click="page('next')"></i>
    </div>
    <Loading v-if="loading" />
    <div v-if="!loading">
      <div class="d-flex" :class="size > 767 ? 'justify-content-end' : 'justify-content-center'">
        <PopupAddEdit :add-edit-fn="addFnYear" :parent-id="excId" title="Add" size="md" type="EVALUATION_YEAR" :is-add="true" :options="{ isAdd: true, refreshData: fetchYear, showMobile: true }" />
      </div>
      <NoResult v-if="isNoResult" />
      <div v-if="!isNoResult">
        <div class="card mt-2" v-for="(item, key) in kpis" :key="key">
          <div class="p-4 pt-4 pb-4 card-header border-bottom d-flex align-items-center justify-content-between" :style="{backgroundColor: randomColor(1)}">
            <div class="col-md-10 col-xl-10 col-xxl-11" :class="size > 767 ? 'd-flex' : ''">
              <div class="col-md-4 col-xl-3 col-xxl-2">
                <div>{{ language('Indicator title') }}</div>
              </div>
              <div class="col-md-8 col-xl-9 col-xxl-10">
                <div>{{ item.goal }}</div>
              </div>
            </div>
            <div class="col-md-2 col-xl-2 col-xxl-1 d-flex justify-content-end">
              <PopupAddEdit :add-edit-fn="addFnYear" :ref-id="item.id" :data="{ kpiYear: item.evaluationYear, kpiGoal: item.goal }" size="md" type="EVALUATION_YEAR" :options="{ refreshData: fetchYear }" />
              <PopupDelete :call-api="delFnYear" :ref-id="item.id" />
            </div>
          </div>
          <div class="p-4 card-body">
            <div class="box-kpi">
              <Quarter :kpis="item.kpiMids" :options="{
                kpiYear: item.evaluationYear,
                kpiGoal: item.goal,
                kpiId: item.id,
                refreshData: fetchData,
                empId: excId,
                collapseIdx: collapseIdx ? collapseIdx : (key === 0 ? item.id : ''),
                isQuarterShow,
              }" />
            </div>
            <div class="box-kpi">
              <Final :kpis="item.kpiFins" :options="{
                kpiYear: item.evaluationYear,
                kpiGoal: item.goal,
                kpiId: item.id,
                refreshData: fetchData,
                empId: excId,
                collapseIdx: collapseIdx ? collapseIdx : (key === 0 ? item.id : ''),
                isQuarterShow,
              }" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
