<script setup>
import { defineProps, computed, inject } from 'vue';
import * as _ from 'lodash';
import { useRouter } from 'vue-router';

import Pagination from '@/components/Pagination.vue';
import PaginationItem from '@/components/PaginationItem.vue';
import PopupDelete from '@/components/Actions/PopupDelete';
import PopupAddEdit from '@/components/Actions/PopupAddEdit';
import { access } from '@/ultis';
import { useMainStore } from '@/store/pinia';

const store = useMainStore();
const accessCode  = computed(() => parseInt(store.auth?.user?.sub_code.value));
const Config = inject('Config');
const router = useRouter();
const l = inject('L');
const tableLanguage = l.table;

const props = defineProps({
  screen: String,
  page: Number,
  actions: Array,
  data: Array,
  ignorekeys: Array,
  editFn: Function,
  viewFn: Function,
  delFn: Function,
  falFn: Function,
  pageFn: Function,
  pageTotal: Number,
  maxHeight: Number,
  isNo: Boolean,
  redirects: Array,
  inPopup: Boolean,
  type: String,
  options: Object,
  sizes: Array,
});

const visibleKeys = computed(() => {
  if (_.size(props.data) === 0) return [];

  const keys = Object.keys(props.data[0]);
  return keys.filter((item) => _.indexOf(props.ignorekeys, item) === -1);
});

const showAction = (action) => {
  return _.indexOf(props.actions, action) !== -1;
};

const hasAction = computed(() => (_.size(props.actions) > 0 && access(accessCode.value, ['MANAGER', 'ADMIN'])) || props.screen === 'favoriteList');
const calculateNo = (no) => (props.page - 1) * Config.limit + no + 1;

const handlePage = (index, disabled) => {
  if (!_.isNumber(index) || index <= 0 || disabled) return;

  if (props.pageFn) {
    return props.pageFn(index);
  }
};

const convertHeader = (text) => _.startCase(_.camelCase(text));

const redirectTo = (item, key) => {
  const url = props.redirects[key];
  const convert = url.split(':');
  return router.push(
    convert[0] + (item[convert[1]] ? item[convert[1]] : 'other')
  );
};

const notlink = (item, key) =>
  !props.redirects || _.size(props.redirects) === 0 || !props.redirects[key];

const language = (text) => {
  if (text === 'Action') return l[_.camelCase(text)];

  return props.screen ? tableLanguage[props.screen][_.camelCase(text)] : text;
};
</script>

<template>
  <div class="table-responsive p-4">
    <div class="table-fix-sceen" :style="{ maxHeight: `${maxHeight}px` }">
      <table
        class="table align-items-center mb-0 table-striped table-bordered table-md"
      >
        <thead>
          <tr>
            <th class="text-center font-weight-bold" v-if="isNo">
              {{ language('No') }}
            </th>
            <th
              v-for="(header, index) in visibleKeys"
              class="px-3 text-center font-weight-bold"
              :key="index"
              :width="sizes ? sizes[index] : 'auto'"
            >
              {{ language(convertHeader(header)) }}
            </th>
            <th class="px-3 font-weight-bold text-center" v-if="hasAction">
              {{ language('Action') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(tr, no) in data" :key="tr.id">
            <td :data-label="language('No')" class="align-middle px-3 text-center" v-if="isNo">
              <span class="text-sm">{{ calculateNo(no) }}</span>
            </td>
            <td
              :data-label="language(convertHeader(td))"
              class="align-middle px-3"
              v-for="(td, key) in visibleKeys"
              :key="key"
            >
              <span class="text-sm" v-if="notlink(tr, key)">{{ tr[td] }}</span>
              <strong
                @click="redirectTo(tr, key)"
                v-if="!notlink(tr, key)"
                class="text-sm link"
                >{{ tr[td] }}</strong
              >
            </td>
            <td :data-label="language('Action')" class="align-middle text-center px-3" v-if="hasAction" >
              <span class="text-secondary font-weight-bold">
                <button
                  class="btn btn-sm btn-icon-only mb-0 mx-1"
                  @click="falFn(tr)"
                  v-if="showAction('F')"
                >
                  <i
                    class="fas fa-star pointer text-success"
                    style="color: #ffffff"
                  ></i>
                </button>
                <button
                  class="btn btn-sm btn-icon-only mb-0 mx-1 btn-info"
                  @click="viewFn(tr)"
                  v-if="showAction('V') && access(accessCode, ['MANAGER', 'ADMIN'])"
                >
                  <i class="far fa-eye"></i>
                </button>
                <PopupAddEdit v-if="showAction('E') && inPopup && access(accessCode, ['MANAGER', 'ADMIN'])" :ref-id="tr.id" :add-edit-fn="editFn" title="Edit" size="md" :type="type" :data="tr.original" :options="options" />
                <PopupDelete :ref-id="tr.id" :call-api="delFn" v-if="showAction('D') && tr.isDelete !== false && access(accessCode, options?.deleteAccess ? options?.deleteAccess : ['MANAGER', 'ADMIN'])" :content="options?.deleteContent" />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Pagination class="justify-content-center mt-3 mb-0" size="md" v-if="pageFn">
      <pagination-item @click="handlePage(page - 1, page - 1 <= 0)" :disabled="page - 1 <= 0" prev />
      <pagination-item @click="handlePage(1)" :label="1" v-if="page > 2" />
      <span class="page-pad" v-if="page > 3">...</span>
      <pagination-item
        @click="handlePage(page - 1)"
        :label="page - 1"
        v-if="page - 1 > 0"
      />
      <pagination-item :label="page" active />
      <pagination-item
        @click="handlePage(page + 1)"
        :label="page + 1"
        v-if="page + 1 <= pageTotal"
      />
      <span class="page-pad" v-if="page < pageTotal - 2">...</span>
      <pagination-item @click="handlePage(pageTotal)" :label="pageTotal" v-if="page < pageTotal - 1" />
      <pagination-item
        @click="handlePage(page+1, page === pageTotal)"
        :disabled="page === pageTotal"
        next
      />
    </Pagination>
  </div>
</template>
