<script setup>
import { ref, watch, defineEmits, inject } from 'vue';
import moment from 'moment';
import * as _ from 'lodash';
import VueDatePicker from '@vuepic/vue-datepicker';

const l = inject('L');
const language = (text) => {
  return l[_.camelCase(text)];
};

const props = defineProps({
  modelValue: String,
  disabled: Boolean,
});

const emit = defineEmits(['update:modelValue', 'change']);

const date = ref(props.modelValue);
const show = ref(props.disabled);

const dateFormat = (date) => {
  return moment(date).format('YYYY-MM-DD');
};

watch(() => props.modelValue, (newVal) => {
  date.value = newVal;
});

watch(() => props.disabled, (newVal) => {
  show.value = newVal;
});

const emitDateChange = () => {
  emit('update:modelValue', dateFormat(date.value));
  emit('change');
};

</script>
<template>
  <VueDatePicker 
    v-model="date" 
    :format="dateFormat"
    :enable-time-picker="false"
    auto-apply
    @update:model-value="emitDateChange"
    :disabled="show"
    :placeholder="language('Select Date')"
  />
</template>
