<script setup>
import { onBeforeUnmount, onBeforeMount, ref, inject } from 'vue';
import { useMainStore } from '@/store/pinia';
import * as _ from 'lodash';
import { useRouter } from 'vue-router';

import AppFooter from '@/components/Footer.vue';
import Input from '@/components/Input.vue';
import Button from '@/components/Button.vue';
import { login } from '@/network/apis/auth';

const store = useMainStore();
const router = useRouter();
const l = inject('L');

onBeforeMount(() => {
  store.setLayout('none');
});
onBeforeUnmount(() => {
  store.setLayout('default');
});

const isSubmit = ref(false);
const username = ref('');
const password = ref('');
const errors = ref({
  username: '',
  password: '',
});

const validateForm = () => {
  errors.value.username = !username.value || _.size(username.value) < 6;
  errors.value.password = !password.value || _.size(password.value) < 8;
};

const handleSubmit = async () => {
  isSubmit.value = true;
  validateForm();

  const checkError = Object.values(errors.value).some(
    (value) => value === true
  );

  if (checkError) {
    return;
  }

  const res = await login({
    username: username.value,
    password: password.value,
  });
  store.setAuth({
    user: res.user,
    token: res.accessToken,
  });

  return router.push('/overview');
};

const language = (text) => {
  return l[_.camelCase(text)];
};
</script>
<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar isBtn="bg-gradient-light" />
      </div>
    </div>
  </div>
  <main class="main-content mt-0">
    <div
      class="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg"
      style="background-image: url(); background-position: top"
      :style="{
        backgroundImage: `url(${require('@/assets/img/bg.jpg')})`,
        backgroundPosition: 'center',
      }"
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 text-center mx-auto">
            <h1 class="text-white mb-2 mt-5">{{ language('Welcome') }}</h1>
            <p class="text-lead text-white">
              {{ language('Login Sub Text') }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0">
            <div class="card-header text-center pt-4">
              <h5>{{ language('loginPageLabel') }}</h5>
            </div>
            <div class="card-body">
              <form role="form" @submit.prevent="handleSubmit">
                <em class="red text-xs" v-if="isSubmit && errors.username">{{
                  !username
                    ? language('This is a required field')
                    : language('Must greater than or equal to 6 characters')
                }}</em>
                <Input
                  id="username"
                  type="text"
                  placeholder="ID"
                  aria-label="Username"
                  v-model="username"
                  :error="isSubmit && errors.username"
                  :success="isSubmit && !errors.username"
                  @input="validateForm"
                />
                <em class="red text-xs" v-if="isSubmit && errors.password">
                  {{
                    !password
                      ? language('This is a required field')
                      : language('Must greater than or equal to 8 characters')
                  }}</em
                >
                <Input
                  id="password"
                  type="password"
                  :placeholder="language('Password')"
                  aria-label="Password"
                  v-model="password"
                  :error="isSubmit && errors.password"
                  :success="isSubmit && !errors.password"
                  @input="validateForm"
                />
                <div class="text-center">
                  <Button
                    fullWidth
                    type="submit"
                    color="dark"
                    variant="gradient"
                    class="my-4 mb-2"
                    >{{ language('Login') }}</Button
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>
