<script setup>
import { inject, defineProps, ref, computed } from 'vue';
import * as _ from 'lodash';
import { useMainStore } from '@/store/pinia';

import { randomColor, access } from '@/ultis';
import PopupDelete from '@/components/Actions/PopupDelete';
import PopupAddEdit from '@/components/Actions/PopupAddEdit';
import { editKpiFin, createKpiFin, deleteKpiFin } from '@/network/apis/kpi';

const size  = computed(() => window.innerWidth);
const l = inject('L');
const store = useMainStore();
const accessCode  = computed(() => parseInt(store.auth?.user?.sub_code.value));

const props = defineProps({
  kpis: Array,
  options: Object,
})

const show = ref(props.options.collapseIdx);
const isShow = ref(props.options.isQuarterShow ? false : true);

const collapse = (index) => {
  if (show.value === index && isShow.value) {
    show.value = 0;
  } else {
    show.value = index;
  }

  isShow.value = true;
};

const addEditFn = async (params, id) => {
  let res = null;

  if (id) {
    res = await editKpiFin(id, params)
  } else {
    res = await createKpiFin(params);
  }

  if (!res) return false;

  return true;
};

const delFn = async (id) => {
  await deleteKpiFin(id);
  props.options.refreshData(props.options.kpiId, false);

  return true;
};

const language = (text) => {
  return l.executiveInfo.forms.evaluation[_.camelCase(text)];
};
</script>
<template>
  <h5 class="mb-2 d-flex justify-content-between">
    <span class="pointer" @click="collapse(options.kpiId)">
      {{ language('Final Kpi') }}
      <i class="fas fa-caret-up" v-if="show === options.kpiId && isShow"></i>
      <i class="fas fa-caret-down" v-if="show !== options.kpiId || (show === options.kpiId && !isShow)"></i>
    </span>
    <PopupAddEdit v-if="access(accessCode, ['MANAGER', 'ADMIN'])" :add-edit-fn="addEditFn" :parent-id="options.empId" title="Add" size="md" type="EVALUATION_FINAL" :is-add="true" :options="{ isAdd: true, ...options }" />
  </h5>
  <div v-if="show === options.kpiId && isShow">
    <div class="box-kpi-item" v-for="(item, k) in kpis" :key="k">
      <div class="d-flex justify-content-between box-kpi-title p-3 pt-2 pb-2" :style="{backgroundColor: randomColor(1, 0.2)}">
        <h6 class="mb-0">{{ item.evaluator }}</h6>
        <div>
          <PopupAddEdit v-if="access(accessCode, ['MANAGER', 'ADMIN'])" :parent-id="options.empId" :ref-id="item.id" :add-edit-fn="addEditFn" title="Edit" size="md" type="EVALUATION_FINAL" :data="{ kpiFinEvalutor: item.evaluator, kpiFinRate: item.rate, kpiFinNote: item.note, kpiFinGrade: item.grade }" :options="options" />
          <PopupDelete :ref-id="item.id" :call-api="delFn" v-if="access(accessCode, ['MANAGER', 'ADMIN'])" />
        </div>
      </div>
      <div class="kpi-item-content mb-4 pt-3 pb-3">
        <div class="row" :class="size < 767 && 'text-center'">
          <div class="col-md-4 col-xl-3 col-xxl-2 p-4 pt-0 pb-0">{{ language('Performance Evaluation') }}</div>
          <div class="col-md-8 col-xl-9 col-xxl-10 p-4 pt-0 pb-0">{{ item.note }}</div>
        </div>
      </div>
    </div>
  </div>
  <NoResult v-if="_.size(kpis) === 0" />
</template>
