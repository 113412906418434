import { callApi } from '../index';

export const listKpi = async (params) => {
  return await callApi('get', `/kpi`,params);
};

export const yearOfApi = async (params) => {
  return await callApi('get', `/kpi/get/year`, params);
};

export const deleteKpi = async (id) => {
  return await callApi('delete', `/kpi/${id}`);
};

export const createKpi = async (params) => {
  return await callApi('post', `/kpi`, params);
};

export const editKpi = async (id, params) => {
  return await callApi('put', `/kpi/${id}`, params);
};

export const deleteKpiMid = async (id) => {
  return await callApi('delete', `/kpi-mid/${id}`);
};

export const createKpiMid = async (params) => {
  return await callApi('post', `/kpi-mid`, params);
};

export const editKpiMid = async (id, params) => {
  return await callApi('put', `/kpi-mid/${id}`, params);
};

export const deleteKpiFin = async (id) => {
  return await callApi('delete', `/kpi-fin/${id}`);
};

export const createKpiFin = async (params) => {
  return await callApi('post', `/kpi-fin`, params);
};

export const editKpiFin = async (id, params) => {
  return await callApi('put', `/kpi-fin/${id}`, params);
};
