<script setup>
import { useRouter } from 'vue-router';
import * as _ from 'lodash';
import { onMounted, ref, watch, inject, computed } from "vue";
import { useMainStore } from '@/store/pinia';
import { access } from '@/ultis';
import { getCompanyExecutive } from "@/network/apis/executives.js";
import { getCompanies } from "@/network/apis/companies";
import Loading from "@/components/Loading";
import NoResult from '@/components/NoResult';


const size  = computed(() => window.innerWidth);
const Config = inject('Config');
const l = inject('L');
const $router = useRouter();
const employees = ref([]);
const companies = ref([]);
const currentCompanyId = ref($router.currentRoute._value.params.id);
const loading = ref(false);
const page = ref(1);
const loadingMore = ref(false);
const scrollComponent = ref(null);
const totalPage = ref(0);
const store = useMainStore();
const accessCode  = computed(() => parseInt(store.auth?.user?.sub_code.value));

const fetchExecutiveCompany = async () => {
  const res = await getCompanyExecutive({
    companyId: currentCompanyId.value === 'other' ? '' : currentCompanyId.value,
    limit: Config.girdLimit,
    page: page.value,
    order: 'ASC',
  });
  if (!res) return;

  totalPage.value = Math.ceil(res.itemCount / Config.girdLimit);
  employees.value = employees.value.concat(res.employees);

  setTimeout(() => {
    loading.value = false;
  }, 1000);
};

const fetchCompanyDropdown = async () => {
  const data = await getCompanies({ limit: 10000 });
  const companiesDropdown = data.companies.map((company) => {
    return {
      value: company.id,
      text: company.companyName,
    };
  });

  companies.value = [
    ...companiesDropdown,
  ];
};

const loadMore = async () => {
  if (loadingMore.value) return;

  loadingMore.value = true;
  if (page.value < totalPage.value) {
    page.value += 1;
    await fetchExecutiveCompany();
  }
  loadingMore.value = false;
};

const handleScroll = () => {
  const { scrollTop, scrollHeight, clientHeight } = scrollComponent.value;
  const isReachedBottom = scrollTop + clientHeight >= scrollHeight - 1;
  if (isReachedBottom) {
    loadMore();
  }
};

const onDropdownChange = () => {
  employees.value = [];
  page.value = 1;
  loading.value = true;
  $router.push(`/executives/company/${currentCompanyId.value}`);
};

const goToView = (id) => {
  $router.push(`/executive/${id}`);
};

onMounted(async () => {
  loading.value = true;

  await fetchExecutiveCompany();
  await fetchCompanyDropdown();
  loading.value = false;
});

watch(currentCompanyId, async () => {
  await fetchExecutiveCompany();
});

const language = (text) => {
  return l[_.camelCase(text)];
};

const goToCreate = () => {
  $router.push('/executive-create?companyId=' + currentCompanyId.value);
};

</script>

<template>
  <div class="card mx-4 company-executive">
    <div class="filter-box p-4 row pb-0">
      <div class="col-10 col-md-5 col-xl-4 col-xxl-3">
        <div class="col-sm">
          <select
            class="form-select"
            v-model="currentCompanyId"
            @change="onDropdownChange"
          >
            <option
              :selected="currentCompanyId"
              v-for="(item, i) in companies"
              :value="item.value"
              :key="i"
            >
              {{ item.text }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-2 col-md-7 col-xl-8 col-xxl-9 d-flex justify-content-end">
        <button v-if="access(accessCode, ['MANAGER', 'ADMIN'])" class="btn btn-sm mb-0 mx-1 p-2 pt-1 pb-1 btn-outline-secondary" @click="goToCreate"><i class="fas fa-plus"></i>  {{ size > 767 ? language('addNewExecutive') : '' }}</button>
      </div>
    </div>
    <hr class="mb-1" :class="size > 767 ? 'mt-4' : 'mt-0'" />
    <Loading v-if="loading" />
    <NoResult v-if="employees.length === 0 && !loading" />
    <div
      @scroll.passive="handleScroll"
      class="p-4 company-executive_grid"
      ref="scrollComponent"
    >
      <div v-if="employees.length > 0 && !loading" class="row gy-4">
        <div
          v-for="employee in employees"
          :key="employee.emp_id"
          class="col-sm-12 col-md-4 col-xl-3"
        >
          <div
            class="card cursor-pointer exct-box"
            @click="goToView(employee.id)"
          >
            <div class="card-body" :class="size < 768 ? 'mobile-custom' : ''">
              <div class="d-flex justify-content-center mb-4 img-box">
                <img
                width="120"
                style="object-fit: cover;"
                  :src="
                    employee.media && employee.media.filePath
                      ? employee.media.filePath
                      : require('@/assets/img/avatar.jpg')
                  "
                  alt="image"
                />
              </div>
              <div class="d-flex flex-column box-content-item">
                <h5 class="card-title text-center mb-0">{{ employee.name }}</h5>
                <p class="card-text text-center">
                  {{ employee?.jobTitle || '' }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="loadingMore" class="col-12 text-center">
          <Loading />
        </div>
      </div>
    </div>
  </div>
</template>
