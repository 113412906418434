<script setup>
import { inject, ref, computed } from "vue";
import * as _ from 'lodash';
import { useRoute } from 'vue-router';
import { useMainStore } from '@/store/pinia';

import Family from './Family/Index';
import Education from './Education/Index';
import Network from './Network/Index';
import PopupAddEdit from '@/components/Actions/PopupAddEdit';

import { createFamily, createEducation, createNetwork } from '@/network/apis/executives';
import { access } from '@/ultis';

const store = useMainStore();
const accessCode  = computed(() => parseInt(store.auth?.user?.sub_code.value));

const route = useRoute();
const excId = route.params.id;
const refresh = ref(1);
const refreshEdu = ref(1);
const refreshNet = ref(1);
const show = ref(1);

const l = inject('L');
const language = (text) => {
  return l.executiveInfo.sections[_.camelCase(text)];
};

const addFamilyFn = async (params) => {
  const res = await createFamily(params);

  if (!res) return false;
  return refresh.value++;
};

const addEducationFn = async (params) => {
  const res = await createEducation(params);

  if (!res) return false;
  return refreshEdu.value++;
};

const addNetworkFn = async (params) => {
  const res = await createNetwork(params);

  if (!res) return false;
  return refreshNet.value++;
};

const collapse = (index) => {
  if (show.value === index) {
    show.value = 0;
  } else {
    show.value = index;
  }
};

</script>
<template>
  <div class="row mt-4">
    <div>
      <div class="card-header p-0 pt-3">
        <h5 class="d-flex justify-content-between">
          <span class="pointer"  @click="collapse(1)">
            {{ language('Family relationship') }}
            <i class="fas fa-caret-up" v-if="show === 1"></i>
            <i class="fas fa-caret-down" v-if="show !== 1"></i>
          </span>
          <PopupAddEdit v-if="show === 1 && access(accessCode, ['MANAGER', 'ADMIN'])" :parent-id="excId" :add-edit-fn="addFamilyFn" title="Add" size="md" type="FAMILY" :is-add="true" />
        </h5>
      </div>

      <div class="collapse" :class="show === 1 ? 'show' : ''">
        <div class="card-body p-2">
          <Family :refresh="refresh" />
        </div>
      </div>
    </div>
    <div>
      <div class="card-header p-0 pt-3">
        <h5 class="d-flex justify-content-between">
          <span class="pointer"  @click="collapse(2)">
            {{ language('Educational history') }}
            <i class="fas fa-caret-up" v-if="show === 2"></i>
            <i class="fas fa-caret-down" v-if="show !== 2"></i>
          </span>
          <PopupAddEdit v-if="show === 2 && access(accessCode, ['MANAGER', 'ADMIN'])" :parent-id="excId" :add-edit-fn="addEducationFn" title="Add" size="md" type="EDUCATION" :is-add="true" />
        </h5>
      </div>

      <div class="collapse" :class="show === 2 ? 'show' : ''">
        <div class="card-body p-2">
          <Education :refresh="refreshEdu" />
        </div>
      </div>
    </div>
    <div>
      <div class="card-header p-0 pt-3">
        <h5 class="d-flex justify-content-between">
          <span class="pointer"  @click="collapse(3)">
            {{ language('Networking Info') }}
            <i class="fas fa-caret-up" v-if="show === 3"></i>
            <i class="fas fa-caret-down" v-if="show !== 3"></i>
          </span>
          <PopupAddEdit v-if="show === 3 && access(accessCode, ['MANAGER', 'ADMIN'])" :parent-id="excId" :add-edit-fn="addNetworkFn" title="Add" size="md" type="NETWORK" :is-add="true" />
        </h5>
      </div>

      <div class="collapse" :class="show === 3 ? 'show' : ''">
        <div class="card-body p-2">
          <Network :refresh="refreshNet" />
        </div>
      </div>
    </div>
  </div>
</template>
