<script setup>
import { ref, onMounted, computed, inject } from 'vue';
import * as _ from 'lodash';
import OverviewChart from '@/components/Charts/Overview.vue';
import Table from '@/components/Table';
import { overview } from '@/network/apis/companies';
import Loading from '@/components/Loading';
import NoResult from '@/components/NoResult';
import { date } from '@/ultis';

const loading = ref(true);
const l = inject('L');
const companies = ref([]);
const totalExct = ref(0);
const ignorekeys = ref(['id']);
const chartKeys = ref({
  label: 'name',
  value: 'members',
});
const language = (text) => {
  return l[_.camelCase(text)];
};
const redirects = ref(['/executives/company/:id', null, null]);

const fetchCompanies = async () => {
  loading.value = true;

  const res = await overview();
  companies.value = _.reduce(
    res.companies,
    (data, item) => {
      data.push({
        id: item.id,
        name: item.name,
        members: item.members,
        modified: date(item.modified),
      });

      return data;
    },
    []
  );
  totalExct.value = res.total;

  setTimeout(() => {
    loading.value = false;
  }, 500);
};

onMounted(() => fetchCompanies());

const size = computed(() => window.innerWidth);

const isNoResult = computed(() => {
  return _.size(companies.value) === 0 && !loading.value;
});
</script>
<template>
  <div class="py-4 container-fluid pt-0">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-12 mb-lg">
            <div class="card z-index-2 overview-item-box">
              <Loading v-if="loading" />
              <OverviewChart
                v-if="!loading"
                :data="companies"
                title="Companies"
                height="300"
                :label-key="chartKeys.label"
                :value-key="chartKeys.value"
                :add-class="size > 767 ? 'py-4 mx-10' : 'py-2 mx-2'"
                :total="totalExct"
              />
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-12 mb-lg-0 mb-4">
            <div class="card overview-table-box">
              <div
                class="d-flex justify-content-end align-items-center px-4 pt-2 font-weight-bold"
              >
                {{ language('total') }}: {{ companies.length }}
              </div>
              <Loading v-if="loading" />
              <NoResult v-if="isNoResult" />
              <Table
                v-if="!loading && !isNoResult"
                :data="companies"
                :ignorekeys="ignorekeys"
                :redirects="redirects"
                screen="overview"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
