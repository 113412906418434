<script setup>
import { inject, computed, defineEmits, ref } from 'vue';
import * as _ from 'lodash';

import YearPicker from '@/components/YearPicker';

const l = inject('L');
const emits = defineEmits(['submitForm']);

const props = defineProps({
  data: {
    kpiMidQuater: String,
    kpiMidRate: Number,
    kpiMidNote: String,
  },
  options: Object,
})

const form = computed(() => ({ ...props.data, kpiMidQuater: props.data?.kpiMidQuater ? props.data.kpiMidQuater : '' }));
const options = computed(() => ({ ...props.options }));

const isSubmit = ref(false);
const errors = ref({
  kpiMidQuater: true,
  kpiMidNote: true,
});

const validateForm = () => {
  const obj = form.value;

  for (const key of Object.keys(obj)) {
    if (errors.value[key]) {
      errors.value[key] = !obj[key];
    }
  }
};

const handleSubmit = async () => {
  isSubmit.value = true;
  validateForm();
  
  const checkError = Object.values(errors.value).some(
    (value) => value === true
  );

  if (checkError) {
    return;
  }

  const obj = _.cloneDeep(form.value);

  emits('submitForm', { ...obj, kpiId: options.value.kpiId });
};

const checkShow = (val) => {
  return _.indexOf(props.options.quaterExts, val) === -1 || props.data?.kpiMidQuater == val;
}

const language = (text, isCommon) => {
  if (isCommon) return l[_.camelCase(text)];

  return l.executiveInfo.forms.evaluation[_.camelCase(text)];
};

defineExpose({
  handleSubmit
});
</script>
<template>
  <form @submit.prevent="handleSubmit">
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="EvaluationYear" class="form-label">{{
          language('Evaluation Year')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <YearPicker v-model="options.kpiYear" @change="validateForm" :disabled="true" :is-string="true" />
        <em class="red text-xs" v-if="isSubmit && errors.kpiYear">{{ language('This is a required field') }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4 text-start">
        <label for="IndicatorTitle" class="form-label">{{
          language('Indicator Title')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="options.kpiGoal"
          id="IndicatorTitle"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="200"
          disabled="true"
        />
        <em class="red text-xs" v-if="isSubmit && errors.kpiGoal">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4 text-start">
        <label for="Quarter" class="form-label">{{
          language('Quarter')
        }}<em class="red">*</em></label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <select
          class="form-select"
          v-model="form.kpiMidQuater"
        >
          <option value="" disabled selected>-- {{ language('Select Quarter') }} --</option>
          <option value="1" v-if="checkShow(1)">{{ language('One St Quarter') }}</option>
          <option value="2" v-if="checkShow(2)">{{ language('Two St Quarter') }}</option>
          <option value="3" v-if="checkShow(3)">{{ language('Three St Quarter') }}</option>
          <option value="4" v-if="checkShow(4)">{{ language('Four St Quarter') }}</option>
        </select>
        <em class="red text-xs" v-if="isSubmit && errors.kpiMidQuater">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4 text-start">
        <label for="AchievementRate" class="form-label">{{
          language('Achievement Rate')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="number"
          class="form-control"
          v-model="form.kpiMidRate"
          id="AchievementRate"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="100"
        />
        <em class="red text-xs" v-if="isSubmit && errors.kpiMidRate">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4 text-start">
        <label for="DetailPrgoress" class="form-label">{{
          language('Detail Progress')
        }}<em class="red">*</em></label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <textarea
          class="form-control"
          v-model="form.kpiMidNote"
          id="DetailPrgoress"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          rows="5"
        ></textarea>
        <em class="red text-xs" v-if="isSubmit && errors.kpiMidNote">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
  </form>
</template>
