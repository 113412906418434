<script setup>
import { onMounted, ref, inject, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import * as _ from 'lodash';
import { toast } from 'vue3-toastify';
import { useMainStore } from '@/store/pinia';
import Loading from '@/components/Loading';
import { executive } from '@/network/apis/executives';
import { deleteFavourite, addFavourite } from '@/network/apis/favourites';
import General from './components/executives/GeneralInfo/General';
import Tab from './components/executives/Tab';
import { access } from '@/ultis';

const store = useMainStore();
const accessCode = computed(() =>
  parseInt(store.auth?.user?.sub_code.value)
);
const route = useRoute();
const userId = route.params.id;
const user = ref();
const loading = ref(true);
const l = inject('L');
const $swal = inject('$swal');
const language = (text, isCommon) => {
  if (isCommon) return l[_.camelCase(text)];

  return l.executiveInfo[_.camelCase(text)];
};
const router = useRouter();

const fetchExecutive = async () => {
  loading.value = true;
  const res = await executive(userId);

  if (!res) {
    setTimeout(() => {
      loading.value = false;
    }, 500);
    return;
  }

  user.value = res.employee;
  setTimeout(() => {
    loading.value = false;
  }, 500);
};

onMounted(() => fetchExecutive());

const favTitle = (name) => {
  return language('Mark Favourite', true).replace('$NAME', name);
}

const handleFavorite = async (isAdd) => {
  if (isAdd) {
    return $swal
      .fire({
        title: favTitle(user.value.name),
        input: 'text',
        inputPlaceholder: language('Enter Note', true),
        inputAttributes: {
          autocapitalize: 'off',
        },
        confirmButtonColor: '#2dce89',
        cancelButtonColor: '#ccc',
        showCancelButton: true,
        confirmButtonText: language('Confirm', true),
        cancelButtonText: language('No', true),
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          await addFavourite({ empId: userId, bmNote: result.value });
          toast.success(language('Favorite Successfully', true));

          return fetchExecutive();
        }
      });
  }

  $swal
    .fire({
      title: `${user.value.name} ${language('Unfavourite', true)}`,
      html: `${language('Are you sure favourite ', true)} ${user.value.name}<br> ${language('from favourite list', true)}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#ccc',
      confirmButtonText: language('Confirm', true),
      cancelButtonText: language('No', true),
      reverseButtons: true,
    })
    .then(async (result) => {
      if (result.isConfirmed) {
        await deleteFavourite(userId);
        toast.success(language('Deleted successfully!', true));
        fetchExecutive();
      }
    });
};
</script>
<template>
  <main>
    <div class="py-4 container-fluid pt-0">
      <div class="row">
        <div class="card">
          <div class="card-header pb-0 px-2">
            <div class="d-row align-items-center">
              <div
                class="mb-0 d-flex align-items-center"
                :class="{
                  'justify-content-center': access(accessCode, [
                    'MANAGER',
                    'ADMIN',
                  ]),
                }"
              >
                <h4 class="mb-0 me-2">{{ language('Excecutive Info') }}</h4>
                <span
                  class="favorite-icon btn-transform"
                  @click="handleFavorite(!user?.hasBookmarks)"
                >
                  <i
                    v-if="!user?.hasBookmarks"
                    class="far fa-heart pointer"
                    style="color: #04de66"
                  ></i>
                  <i
                    v-if="user?.hasBookmarks"
                    class="fas fa-heart pointer"
                    style="color: #04de66"
                  ></i>
                </span>
                <button
                  v-if="access(accessCode, ['MANAGER', 'ADMIN'])"
                  class="btn btn-xs btn-outline-secondary ms-auto mb-0"
                  @click="router.push(`/executive-edit/${userId}`)"
                >
                  <i class="fas fa-edit pointer"></i>
                  {{ language('Edit Info') }}
                </button>
              </div>
            </div>
          </div>
          <div class="card-body px-2">
            <Loading v-if="loading" height="500px" />
            <General :user="user" v-if="!loading" />
            <div v-if="!loading" class="row info-box mt-3">
              <Tab class="py-4" :general-info="user" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
