<script setup>
import { computed, defineProps, watch, inject } from 'vue';
import { Bar } from 'vue-chartjs';
import * as _ from 'lodash';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const size  = computed(() => window.innerWidth);
const l = inject('L');
const language = (text) => {
  return l.executiveInfo.compensation[_.camelCase(text)];
};

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend, ChartDataLabels);

const props = defineProps({
  data: Array,
  height: Number,
});

const chartData = computed(() => ({
  labels: props.data.labels,
  datasets: props.data.datasets,
}));

const options = computed(() => ({
  responsive: true,
  plugins: {
    legend: {
      position: size.value > 767 ? 'right' : 'bottom',
      labels: {
        font: {
          size: size.value > 767 ? 24 : 14
        }
      }
    },
    datalabels: {
      anchor: 'end',
      align: 'top',
      formatter: (value) => `${value} ${language('won')}`,
      font: {
        size: size.value > 767 ? 12 : 8,
        weight: 'bold'
      }
    }
  },
  layout: {
    padding: {
      left: size.value > 767 ? 25 : 0,
      right: size.value > 767 ? 25 : 0,
      top: 50,
      bottom: 25
    }
  },
  scales: {
    x: {
      stacked: false,
      grid: {
        display: false,
      },
    },
    y: {
      display: false,
      stacked: false,
      grid: {
        display: false,
      },
    }
  }
}));

watch(
  () => props.data,
  () => {
  },
  { deep: true }
);
</script>

<template>
  <div class="chart text-center" :style="{height: `${height}px`}">
    <Bar :data="chartData" :options="options" :height="size < 768 && '400px'" />
  </div>
</template>
