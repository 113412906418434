<script setup>
import { ref, onMounted, computed, inject, watch } from 'vue';
import Table from '@/components/Table';
import Loading from '@/components/Loading';
import NoResult from '@/components/NoResult';
import { listLog } from '@/network/apis/log';
import * as _ from 'lodash';
import { datetime } from '@/ultis';

import DatePicker from '@/components/DatePicker';

const Config = inject('Config');
const l = inject('L');
const params = ref({
  limit: Config.limit,
  page: 1,
});
const loading = ref(true);
const logs = ref([]);
const actions = ref([]);
const total = ref(0);
const ignorekeys = ref(['id']);
const keyword = ref('');
const fromDate = ref('');
const toDate = ref('');
const sizes = ref(['120px', 'auto', '150px', '150px']);

const fetchData = async () => {
  loading.value = true;

  const res = await listLog(params.value);
  if (!res) return;

  logs.value = _.reduce(res.screenLogs, (data, item) => {
    data.push({
      ...item,
      loginTime: datetime(item.loginTime),
      logoutTime: datetime(item.logoutTime),
    });

    return data;
  }, []);
  total.value = res.itemCount;

  setTimeout(() => {
    loading.value = false;
  }, 500);
};

onMounted(() => fetchData());

const pageTotal = computed(() => {
  return total.value % Config.limit > 0
    ? parseInt(total.value / Config.limit) + 1
    : total.value / Config.limit;
});

const pageFn = (index) => {
  params.value.page = index;
};
const handleSearch = (e) => {
  if (!keyword.value && e && e.which != 8) return;

  params.value = {
    ...params.value,
    page: 1,
    keyword: keyword.value,
    from: fromDate.value,
    to: toDate.value
  };
};

const isNoResult = computed(() => {
  return _.size(logs.value) === 0 && !loading.value;
});

watch(
  params,
  () => {
    fetchData();
  },
  { deep: true }
);

const language = (text) => {
  return l[_.camelCase(text)];
};

const clearDate = (isFrom) => {
  if (isFrom) {
    fromDate.value = '';
  } else {
    toDate.value = '';
  }

  handleSearch()
};
</script>

<template>
  <div class="card mx-4">
    <div class="d-flex justify-content-between">
      <div class="filter-box p-4 row pb-0 w-75">
        <div class="col-md-5 col-xl-4 col-xxl-3">
          <label for="KeywordInput" class="form-label">{{
            language('Keyword')
          }}</label>
          <input
            type="email"
            class="form-control"
            v-model="keyword"
            id="KeywordInput"
            @keyup="handleSearch"
            :placeholder="language('Enter keyword')"
          />
        </div>
        <div class="col-md-3 col-xl-3 col-xxl-2">
          <label for="From" class="form-label">{{
            language('From')
          }}</label>
          <div class="box-date">
            <DatePicker v-model="fromDate" @change="handleSearch" />
            <i v-if="fromDate" class="fas fa-times" @click="clearDate(true)"></i>
          </div>
        </div>
        <div class="col-md-3 col-xl-3 col-xxl-2">
          <label for="To" class="form-label">{{
            language('To')
          }}</label>
          <div class="box-date">
            <DatePicker v-model="toDate" @change="handleSearch" />
            <i v-if="toDate" class="fas fa-times" @click="clearDate()"></i>
          </div>
        </div>
      </div>
    </div>

    <hr class="mb-0 mt-4" />
    <div
      class="d-flex justify-content-end align-items-center px-4 pt-2 font-weight-bold"
    >
      {{ language('total') }}: {{ total }}
    </div>
    <div class="card-body px-0 pt-0 pb-2 content-box">
      <Loading v-if="loading" />
      <NoResult v-if="isNoResult" />
      <Table
        v-if="!loading && !isNoResult"
        :data="logs"
        :ignorekeys="ignorekeys"
        :pageTotal="pageTotal"
        :isNo="true"
        :actions="actions"
        :pageFn="pageFn"
        :page="params.page"
        screen="logList"
        :max-height="550"
        :sizes="sizes"
      />
    </div>
  </div>
</template>
