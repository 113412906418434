<script setup>
import { inject, computed, defineEmits, ref } from 'vue';
import * as _ from 'lodash';

import YearPicker from '@/components/YearPicker';

const l = inject('L');
const emits = defineEmits(['submitForm']);

const props = defineProps({
  data: {
    kpiFinEvalutor: String,
    kpiFinNote: String,
    kpiFinRate: Number,
    kpiFinGrade: String
  },
  options: Object,
})

const form = computed(() => ({ ...props.data }));
const options = computed(() => ({ ...props.options }));

const isSubmit = ref(false);
const errors = ref({
  kpiFinEvalutor: true,
  kpiFinNote: true,
});

const validateForm = () => {
  const obj = form.value;

  for (const key of Object.keys(obj)) {
    if (errors.value[key]) {
      errors.value[key] = !obj[key];
    }
  }
};

const handleSubmit = async () => {
  isSubmit.value = true;
  validateForm();
  
  const checkError = Object.values(errors.value).some(
    (value) => value === true
  );

  if (checkError) {
    return;
  }

  const obj = _.cloneDeep(form.value);

  emits('submitForm', { ...obj, kpiId: options.value.kpiId });
};

const language = (text, isCommon) => {
  if (isCommon) return l[_.camelCase(text)];

  return l.executiveInfo.forms.evaluation[_.camelCase(text)];
};

defineExpose({
  handleSubmit
});
</script>
<template>
  <form @submit.prevent="handleSubmit">
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="EvaluationYear" class="form-label">{{
          language('Evaluation Year')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <YearPicker v-model="options.kpiYear" @change="validateForm" :disabled="true" :is-string="true" />
        <em class="red text-xs" v-if="isSubmit && errors.kpiYear">{{ language('This is a required field') }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4 text-start">
        <label for="IndicatorTitle" class="form-label">{{
          language('Indicator Title')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="options.kpiGoal"
          id="IndicatorTitle"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="100"
          disabled="true"
        />
        <em class="red text-xs" v-if="isSubmit && errors.kpiGoal">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4 text-start">
        <label for="Evaluator" class="form-label">{{
          language('Evaluator')
        }}<em class="red">*</em></label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="form.kpiFinEvalutor"
          id="Evaluator"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="20"
        />
        <em class="red text-xs" v-if="isSubmit && errors.kpiFinEvalutor">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4 text-start">
        <label for="Rate" class="form-label">{{
          language('Rate')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="number"
          class="form-control"
          v-model="form.kpiFinRate"
          id="Rate"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
        />
        <em class="red text-xs" v-if="isSubmit && errors.kpiFinRate">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4 text-start">
        <label for="Grade" class="form-label">{{
          language('Grade')
        }}</label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="form.kpiFinGrade"
          id="Grade"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="20"
        />
        <em class="red text-xs" v-if="isSubmit && errors.kpiFinGrade">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4 text-start">
        <label for="DetailEvaluation" class="form-label">{{
          language('Detail Evaluation')
        }}<em class="red">*</em></label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <textarea
          class="form-control"
          v-model="form.kpiFinNote"
          id="DetailEvaluation"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          rows="5"
        ></textarea>
        <em class="red text-xs" v-if="isSubmit && errors.kpiFinNote">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
  </form>
</template>
