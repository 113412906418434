import { createApp } from "vue";
import VueSweetalert2 from 'vue-sweetalert2';
import Vue3Toasity from 'vue3-toastify';
import { MaskInput } from 'vue-3-mask';
import { createPinia } from 'pinia';

import App from "./App.vue";
import router from "./router";

import '@vuepic/vue-datepicker/dist/main.css'
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue3-toastify/dist/index.css';
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import "./assets/scss/index.scss";
import "./assets/css/style.css";
import 'bootstrap/dist/js/bootstrap.bundle.js';
import Config from './config';
import Language from './consts/language';
import Errors from './consts/errors';

const pinia = createPinia();

const appInstance = createApp(App);
appInstance.provide('Config', Config);
appInstance.provide('Errors', Errors);
appInstance.provide('L', Language);
appInstance.use(VueSweetalert2);
appInstance.use(
  Vue3Toasity,
  {
    autoClose: 5000,
  }
);
appInstance.use(pinia);
appInstance.use(router);
appInstance.component('input-mask', MaskInput);
appInstance.mount("#app");
