<script setup>
import { inject, computed, defineEmits, ref } from 'vue';
import * as _ from 'lodash';

import YearPicker from '@/components/YearPicker';

const l = inject('L');
const emits = defineEmits(['submitForm']);

const props = defineProps({
  data: {
    kpiYear: String,
    kpiGoal: String,
  },
  options: Object,
})

const form = computed(() => ({ ...props.data }));

const isSubmit = ref(false);
const errors = ref({
  kpiYear: true,
  kpiGoal: true,
});

const validateForm = () => {
  const obj = form.value;

  for (const key of Object.keys(obj)) {
    if (errors.value[key]) {
      errors.value[key] = !obj[key];
    }
  }
};

const handleSubmit = async () => {
  isSubmit.value = true;
  validateForm();
  
  const checkError = Object.values(errors.value).some(
    (value) => value === true
  );

  if (checkError) {
    return;
  }

  const obj = _.cloneDeep(form.value);
  obj.kpiYear = obj.kpiYear.toString();

  emits('submitForm', obj);
};

const language = (text, isCommon) => {
  if (isCommon) return l[_.camelCase(text)];

  return l.executiveInfo.forms.evaluation[_.camelCase(text)];
};

defineExpose({
  handleSubmit
});
</script>
<template>
  <form @submit.prevent="handleSubmit">
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4">
        <label for="EvaluationYear" class="form-label">{{
          language('Evaluation Year')
        }}<em class="red">*</em></label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <YearPicker v-model="form.kpiYear" @change="validateForm" :is-string="true" />
        <em class="red text-xs" v-if="isSubmit && errors.kpiYear">{{ language('This is a required field') }}</em>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-5 col-xl-5 col-xxl-4 text-start">
        <label for="IndicatorTitle" class="form-label">{{
          language('Indicator Title')
        }}<em class="red">*</em></label>
      </div>
      <div class="col-md-7 col-xl-7 col-xxl-8">
        <input
          type="text"
          class="form-control"
          v-model="form.kpiGoal"
          id="IndicatorTitle"
          :placeholder="language('Enter your text', true)"
          @input="validateForm"
          maxlength="200"
        />
        <em class="red text-xs" v-if="isSubmit && errors.kpiGoal">{{ language('This is a required field', true) }}</em>
      </div>
    </div>
  </form>
</template>
