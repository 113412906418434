<script setup>
import { ref, onMounted, computed, inject, watch } from 'vue';
import * as _ from 'lodash';

import Table from '@/components/Table';
import Loading from '@/components/Loading';
import NoResult from '@/components/NoResult';
import { listMaster, deleteMaster, editMaster, createMaster } from '@/network/apis/mastercodes';
import PopupAddEdit from '@/components/Actions/PopupAddEdit';

const Config = inject('Config');
const l = inject('L');
const params = ref({
  limit: Config.limit,
  page: 1,
});
const loading = ref(true);
const masters = ref([]);
const actions = ref(['E', 'D']);
const totalExct = ref(0);
const ignorekeys = ref(['id', 'regDt', 'modDt', 'isDelete', 'original']);
const searchBy = ref([
  { label: '마스터 코드', value: 'Master code' },
  { label: '코드명', value: 'Code name' },
]);
const searchField = ref('Master code');
const keyword = ref('');

const fetchData = async () => {
  loading.value = true;

  const res = await listMaster(params.value);
  if (!res) return;

  masters.value = _.reduce(res.masterCodes, (data, item) => {
    data.push({ ...item, id: item.masterCode, original: {
      mstCode: item.masterCode,
      mstNm: item.codeName,
      orderNum: item.orderNum,
    } });

    return data;
  }, []);
  totalExct.value = res.itemCount;

  setTimeout(() => {
    loading.value = false;
  }, 500);
};

onMounted(() => fetchData());

const pageTotal = computed(() => {
  return totalExct.value % Config.limit > 0
    ? parseInt(totalExct.value / Config.limit) + 1
    : totalExct.value / Config.limit;
});

const pageFn = (index) => {
  params.value.page = index;
};

const addEditFn = async (params, id) => {
  let res = null;

  if (id) {
    res = await editMaster(id, params)
  } else {
    res = await createMaster(params);
  }

  if (!res) return false;

  return true;
};

const delFn = async (id) => {
  await deleteMaster(id);
  fetchData();

  return true;
};

const handleSearch = (e) => {
  if (!keyword.value && e.which != 8) return;

  params.value = {
    ...params.value,
    page: 1,
    searchBy: _.camelCase(searchField.value),
    keyword: keyword.value,
  };
};

const isNoResult = computed(() => {
  return _.size(masters.value) === 0 && !loading.value;
});

watch(
  params,
  () => {
    fetchData();
  },
  { deep: true }
);

const language = (text) => {
  return l[_.camelCase(text)];
};
</script>

<template>
  <div class="card mx-4">
    <div class="d-flex justify-content-between">
      <div class="filter-box p-4 row pb-0 w-75">
        <div class="col-md-3 col-xl-3 col-xxl-2">
          <label for="exampleFormControlInput1" class="form-label">{{
            language('Search by')
          }}</label>
          <select
            class="form-select"
            v-model="searchField"
            @change="handleSearch"
          >
            <option v-for="(item, i) in searchBy" :value="item.value" :key="i">
              {{ item.label }}
            </option>
          </select>
        </div>
        <div class="col-md-5 col-xl-4 col-xxl-3">
          <label for="KeywordInput" class="form-label">{{
            language('Keyword')
          }}</label>
          <input
            type="text"
            class="form-control"
            v-model="keyword"
            id="KeywordInput"
            @keyup="handleSearch"
            :placeholder="language('Enter keyword')"
          />
        </div>
      </div>
      <div v-if="!loading" class="w-25 align-items-center p-4 mt-4 d-flex justify-content-end">
        <PopupAddEdit :add-edit-fn="addEditFn" title="Add" size="md" type="MASTER" :is-add="true" :options="{ isAdd: true, refreshData: fetchData }" />
      </div>
    </div>
    <hr class="mb-0 mt-4" />
    <div
      class="d-flex justify-content-end align-items-center px-4 pt-2 font-weight-bold"
    >
      {{ language('total') }}: {{ totalExct }}
    </div>
    <div class="card-body px-0 pt-0 pb-2 content-box">
      <Loading v-if="loading" />
      <NoResult v-if="isNoResult" />
      <Table
        v-if="!loading && !isNoResult"
        :data="masters"
        :ignorekeys="ignorekeys"
        :pageTotal="pageTotal"
        :isNo="true"
        :actions="actions"
        :pageFn="pageFn"
        :editFn="addEditFn"
        :delFn="delFn"
        :page="params.page"
        screen="masterCodeList"
        :inPopup="true"
        type="MASTER"
        :options="{ refreshData: fetchData, deleteContent: 'Delete master code' }"
      />
    </div>
  </div>
</template>
