import { createRouter, createWebHistory, useRouter } from 'vue-router';
import { useMainStore } from '@/store/pinia';

import Overview from '../views/Overview.vue';
import Executives from '../views/Executives.vue';
import Favorite from '../views/Favorite.vue';
import Master from '../views/Master.vue';
import SubMaster from '../views/SubMaster.vue';
import Company from '../views/Company.vue';
import User from '../views/User.vue';
import Login from '../views/Login.vue';
import CompanyExecutives from '../views/CompanyExecutives.vue';
import ExecutiveCreate from '../views/ExecutiveCreate.vue';
import ExecutiveEdit from '../views/ExecutiveEdit.vue';
import Log from '../views/Log.vue';
import Page403 from '../views/403.vue';

const routes = [
  {
    path: '/',
    name: '/',
    redirect: '/overview',
    meta: { requiresAuth: true },
  },
  {
    path: '/overview',
    name: 'Overview',
    component: Overview,
    meta: { requiresAuth: true },
  },
  {
    path: '/executives',
    name: 'Executives',
    component: Executives,
    meta: { requiresAuth: true },
  },
  {
    path: '/executives/company/:id',
    name: 'Executives Company',
    component: CompanyExecutives,
    meta: { requiresAuth: true },
  },
  {
    path: '/favorite',
    name: 'Favorite',
    component: Favorite,
    meta: { requiresAuth: true },
  },
  {
    path: '/masters',
    name: 'Master Data',
    component: Master,
    meta: { requiresAuth: true, accessCode: 20 },
  },
  {
    path: '/sub-masters',
    name: 'Sub Master Data',
    component: SubMaster,
    meta: { requiresAuth: true, accessCode: 20 },
  },
  {
    path: '/companies',
    name: 'Companies',
    component: Company,
    meta: { requiresAuth: true, accessCode: 20 },
  },

  {
    path: '/executive-create',
    name: 'ExecutiveCreate',
    component: ExecutiveCreate,
    meta: { requiresAuth: true, accessCode: 20 },
  },
  {
    path: '/executive-edit/:id',
    name: 'ExecutiveEdit',
    component: ExecutiveEdit,
    meta: { requiresAuth: true, accessCode: 20 },
  },
  {
    path: '/executive/:id',
    name: 'Executive',
    component: User,
    meta: { requiresAuth: true },
  },
  {
    path: '/log',
    name: 'Log',
    component: Log,
    meta: { requiresAuth: true, accessCode: 20 },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/403',
    name: '403',
    component: Page403,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active',
});

router.beforeEach(async (to, from, next) => {
  const store = useMainStore();
  const isAuth = store.auth?.token;
  const accessCode = parseInt(store.auth?.user?.sub_code.value);
  const meta = to.meta;
  const router = useRouter();

  if ((meta.requiresAuth && !isAuth)) return router.push('/login');
  if (meta.accessCode && accessCode < meta.accessCode) return router.push('/403');

  store.setNav(false);
  next();
});

export default router;
