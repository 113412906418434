<script setup>
import { computed } from "vue";
import List from './List.vue';
import logo from '@/assets/img/logo.png';
import { useMainStore } from '@/store/pinia';

const store = useMainStore();

const showNav  = computed(() => store.showNav);
</script>

<template>
  <div class="min-height-300 position-absolute w-100 bgcolor" />
  <aside
    class="my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl fixed-start ms-3 bg-white"
    id="sidenav-main"
    :class="showNav ? 'show-nav-mobile' : ''"
  >
    <div class="sidenav-header">
      <router-link class="m-0 navbar-brand text-center" to="/">
        <img :src="logo" class="navbar-brand-img h-100" alt="main_logo" />
      </router-link>
    </div>
    <hr class="mt-0 horizontal dark" />
    <List />
  </aside>
</template>
